import { theme } from '@gohubly/design-system';
import { OLIST_HOST_NAME, ORGANIZATION_CUSTOMIZATION_KEY } from '@plug/config/consts';
import getCurrentOrganization from '@plug/helpers/get-current-organization';
import getCustomizatedColor from '@plug/helpers/get-customizated-color';
import { getLocalInfo } from '@plug/helpers/local-info';
import { createGlobalStyle, css } from 'styled-components';

const isOrganizationDomain =
  !window.location.pathname.includes('dashboard') &&
  !window.location.pathname.includes('login') &&
  !window.location.pathname.includes('signup') &&
  !window.location.pathname.includes('recovery-password');

export default createGlobalStyle`
  :root {
    --swiper-theme-color: ${(): string =>
      getCustomizatedColor('buttons', theme.colors.primary)} !important;
  }

  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Inter;
  }
  * {
    font-family: Inter, sans-serif;
  }

  #root {
    height: 100%;
  }
  body {
    html {
      box-sizing: border-box;
    }

    background-color: #F1F2F9;
  }
  *, ::after, ::before {
    box-sizing: border-box;
  }

  /* Intercom custom css settings */
  div.intercom-lightweight-app-launcher.intercom-launcher {
    display: none;
    bottom: 15px !important;
    right: 15px !important;
  }

  .react-tooltip {
    z-index: 99999999 !important;
  }

  .avatar-image {
    background-position: center !important;
  }

  /* Remove blue from autocomplete */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    color: #1E1044;
    font-size: 13.3px;
  }

  input::placeholder {
    font-size: 16px;
    color: #72719E;
  }

  .swiper-pagination-clickable {
    bottom: 0;
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  .admin.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    margin-top: 4px;
  }

  .admin-link.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    margin-top: 16px;
  }

  div.intercom-app {
    display: none !important;
  }

  #crisp-chatbox a { display: none !important; }

  ${(): any => {
    if (
      window.location.host.includes('afiliados.madesa.com') ||
      window.location.host.includes('parceiro.efizi.com.br') ||
      window.location.host.includes('influencer.anapaulacarvalho.com') ||
      window.location.host.includes('parceiro.espacosmart.com.br') ||
      window.location.host.includes('parceira.marimariamakeup.com') ||
      window.location.host.includes('parceiros.lindacasa.com.br') ||
      window.location.host.includes('parceiro.lojakingspan.com') ||
      OLIST_HOST_NAME.includes(window.location.host)
    ) {
      return css`
        #crisp-chatbox a {
          display: none !important;
        }
      `;
    }
  }}
  
  ${(): any => {
    if (
      window.location.hostname.includes('parceiro.efizi.com.br') &&
      getCurrentOrganization()?.organizationRole[0]?.name !== 'ADMIN'
    ) {
      return css`
        .price,
        .price-cart,
        .cart-total-table,
        .totalizer {
          color: white !important;
          opacity: 0 !important;
          visibility: hidden !important;
          pointer-events: none !important;
        }
      `;
    }
  }}
  
  iframe:not(.terms-iframe, #jsd-widget) {
    display: none !important;
    pointer-events: none !important;
  }

  .terms-iframe {
    width: 100%;
    height: 100%;
  }

  ${(): any => {
    if (OLIST_HOST_NAME.includes(window.location.host)) {
      return css`
        #tiny-go-hubly-contato {
          display: flex !important;
          pointer-events: initial !important;
        }
      `;
    }
  }}

  .jsd-show { display: block !important };
  .jsd-hide { display: none !important };

  .table-full {
    flex: 1;
  }

  .header {
    margin: 16px;
    margin-bottom: 0;
    justify-content: flex-end;

    @media screen and (max-width: 768px) {
      margin: 8px;
      padding: 12px 16px;
    }
  }

  .ds-button-tonalFilled {
    color: #333333;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
`;
