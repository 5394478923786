import React from 'react';
import ReactDOM from 'react-dom';

import 'react-toastify/dist/ReactToastify.css';

import './i18n';
import { Helmet } from 'react-helmet';

import App from './app';
import * as serviceWorker from './serviceWorker';
import { MARY_KAY_WHITELABEL, OLIST_HOST_NAME } from './config/consts';

const mappedFaviconsDomain = [
  ...OLIST_HOST_NAME,
  'parceiro.efizi.com.br',
  'influencer.anapaulacarvalho.com',
  'parceiro.espacosmart.com.br',
  'afiliados.madesa.com',
  'edvitrine.lojafebracis.com.br',
  'parceira.marimariamakeup.com',
  'parceiros.lindacasa.com.br',
  'parceiro.lojakingspan.com',
  ...MARY_KAY_WHITELABEL,
];

ReactDOM.render(
  <>
    {mappedFaviconsDomain.includes(window.location.hostname) ? (
      <Helmet>
        {window.location.hostname.includes('parceiro.espacosmart.com.br') && (
          <script
            type="text/javascript"
            async
            src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/87b03912-7579-4e95-a948-46224d6f6e9d-loader.js"
          ></script>
        )}

        {window.location.hostname.includes('parceiro.efizi.com.br') && (
          <script
            type="text/javascript"
            async
            src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/ef32691c-8082-4134-871b-6d77d546f34b-loader.js"
          ></script>
        )}

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`https://plugone-production.nyc3.digitaloceanspaces.com/assets/favicons/${window.location.hostname}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`https://plugone-production.nyc3.digitaloceanspaces.com/assets/favicons/${window.location.hostname}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`https://plugone-production.nyc3.digitaloceanspaces.com/assets/favicons/${window.location.hostname}/favicon-16x16.png`}
        />
        <link
          rel="manifest"
          href={`https://plugone-production.nyc3.digitaloceanspaces.com/assets/favicons/${window.location.hostname}/site.webmanifest`}
        />
        <link
          rel="mask-icon"
          href={`https://plugone-production.nyc3.digitaloceanspaces.com/assets/favicons/${window.location.hostname}/safari-pinned-tab.svg`}
        />
      </Helmet>
    ) : (
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
    )}
    <App />
  </>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
