export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Datetime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  /** Date custom scalar type */
  Date: any;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  getUser: User;
  getOriginalUrlByCode: Scalars['String'];
  verifyOrganizationName?: Maybe<Scalars['Boolean']>;
  listMyOrganizations: Array<Maybe<Organization>>;
  organizationDetails: Organization;
  listUsersInOrganization: ListUsersInOrganizationPayload;
  listTeammates: Array<Maybe<UserOrganization>>;
  findUsersToOrganization: Array<Maybe<FindUsersToOrganizationPayload>>;
  teammatesCapacities: TeammatesCapacities;
  listAvailableServices: Array<Maybe<Service>>;
  listAvailableUsersToService: Array<Maybe<UserOrganization>>;
  listAffiliatesMembers: ListAffiliatesMembersPayload;
  getUserInOrganizationService?: Maybe<UserOrganizationService>;
  getUserOrganizationByServiceName?: Maybe<UserOrganizationService>;
  getUserInOrganizationServiceById?: Maybe<UserOrganizationService>;
  affiliatesCapacities?: Maybe<AffiliatesCapacities>;
  vtexDepartmentsCommissions: Array<Maybe<VtexDepartmentsComissionsAdapted>>;
  vtexAffiliateCommission: Array<Maybe<VtexDepartmentsComissionsPercentage>>;
  timeToPayCommission: TimeToPayCommission;
  defaultCommission: DefaultCommission;
  listAffiliateShorterUrl: Array<Maybe<UserOrganizationServiceRolesUrlShortener>>;
  getAllOrganizationOrders: AllOrders;
  getOrganizationOrdersByAffiliateId: AllOrders;
  getOrganizationRevenue: OrganizationRevenue;
  getOrganizationAverageTicket: OrganizationAverageTicket;
  getOrganizationTotalOrders: OrganizationTotalOrders;
  getOrganizationCommission: OrganizationCommission;
  getOrganizationTotalOrdersByAffiliate: OrganizationTotalOrders;
  getOrganizationCommissionByAffiliate: OrganizationCommission;
  userOrganizationPermissions: Array<Maybe<OrganizationPermissions>>;
  userServicePermissions: Array<Maybe<ServicePermissions>>;
  menuTree: Array<Maybe<MenuTree>>;
  getBrazilBanks: Array<BrazilBanks>;
  listAvailablePlans: Array<Maybe<Plan>>;
  listOrganizationCustomerPayment: Array<Maybe<OrganizationCustomerPayment>>;
  getSubscriptionByOrganizationId: Subscription;
};

export type QueryGetOriginalUrlByCodeArgs = {
  input: GetOriginalUrlByCodeInput;
};

export type QueryVerifyOrganizationNameArgs = {
  input: VerifyOrganizationNameInput;
};

export type QueryListUsersInOrganizationArgs = {
  input: ListUsersInOrganizationInput;
};

export type QueryFindUsersToOrganizationArgs = {
  input: FindUsersToOrganizationInput;
};

export type QueryListAvailableUsersToServiceArgs = {
  input: ListAvailableUsersToServiceInput;
};

export type QueryListAffiliatesMembersArgs = {
  input?: Maybe<ListAffiliatesMembersInput>;
};

export type QueryGetUserInOrganizationServiceArgs = {
  input: GetUserInOrganizationServiceInput;
};

export type QueryGetUserOrganizationByServiceNameArgs = {
  input: GetUserOrganizationByServiceNameInput;
};

export type QueryGetUserInOrganizationServiceByIdArgs = {
  input: GetUserInOrganizationServiceByIdInput;
};

export type QueryVtexAffiliateCommissionArgs = {
  input: VtexAffiliateCommissionInput;
};

export type QueryListAffiliateShorterUrlArgs = {
  input: ListAffiliateShorterUrlInput;
};

export type QueryGetAllOrganizationOrdersArgs = {
  input?: Maybe<GetAllOrganizationOrdersInput>;
};

export type QueryGetOrganizationOrdersByAffiliateIdArgs = {
  input?: Maybe<GetOrganizationOrdersByAffiliateIdInput>;
};

export type QueryGetOrganizationRevenueArgs = {
  input?: Maybe<GetOrganizationRevenueInput>;
};

export type QueryGetOrganizationAverageTicketArgs = {
  input?: Maybe<GetOrganizationAverageTicketInput>;
};

export type QueryGetOrganizationTotalOrdersArgs = {
  input?: Maybe<GetOrganizationTotalOrdersInput>;
};

export type QueryGetOrganizationCommissionArgs = {
  input?: Maybe<GetOrganizationCommissionInput>;
};

export type QueryGetOrganizationTotalOrdersByAffiliateArgs = {
  input: GetOrganizationTotalOrdersByAffiliateInput;
};

export type QueryGetOrganizationCommissionByAffiliateArgs = {
  input: GetOrganizationCommissionByAffiliateInput;
};

export type QueryUserOrganizationPermissionsArgs = {
  input?: Maybe<UserOrganizationPermissionsInput>;
};

export type QueryUserServicePermissionsArgs = {
  input: UserServicePermissionsInput;
};

export type QueryGetBrazilBanksArgs = {
  input?: Maybe<GetBrazilBanksInput>;
};

export type QueryGetSubscriptionByOrganizationIdArgs = {
  input?: Maybe<GetSubscriptionByOrganizationIdInput>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  organizations: Array<Maybe<Organization>>;
};

export type UserOrganizationsArgs = {
  organizationId?: Maybe<Scalars['String']>;
};

export type Organization = {
  __typename?: 'Organization';
  integration?: Maybe<Integration>;
  name: Scalars['String'];
  contactEmail: Scalars['String'];
  id: Scalars['String'];
  slug: Scalars['String'];
  active: Scalars['Boolean'];
  hasBillingPendency?: Maybe<Scalars['Boolean']>;
  user: User;
  showFirstSteps: Scalars['Boolean'];
  updatedAt: Scalars['Datetime'];
  freeTrial: Scalars['Boolean'];
  freeTrialExpires?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  logo?: Maybe<Scalars['String']>;
  hasMember: Scalars['Boolean'];
  organizationRole: Array<Maybe<OrganizationRole>>;
  services: Array<Maybe<Service>>;
};

export type Integration = {
  __typename?: 'Integration';
  type: Integrations;
  status: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export enum Integrations {
  Vtex = 'vtex',
  VtexIo = 'vtex_io',
  LojaIntegrada = 'loja_integrada',
  Tiny = 'tiny',
  Yampi = 'yampi',
  Iugu = 'iugu',
  Wake = 'wake',
  Tray = 'tray'
}

export type OrganizationRole = {
  __typename?: 'OrganizationRole';
  id: Scalars['String'];
  name: OrganizationRoleName;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export enum OrganizationRoleName {
  Admin = 'ADMIN',
  Member = 'MEMBER',
}

export type Service = {
  __typename?: 'Service';
  id: Scalars['String'];
  name: Scalars['String'];
  active: Scalars['Boolean'];
  updatedAt: Scalars['Datetime'];
  createdAt: Scalars['Datetime'];
  hasOrganization?: Maybe<Scalars['Boolean']>;
  serviceRoles: Array<Maybe<ServiceRoles>>;
};

export type ServiceRoles = {
  __typename?: 'ServiceRoles';
  id: Scalars['String'];
  name: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type GetOriginalUrlByCodeInput = {
  urlCode: Scalars['String'];
};

export type VerifyOrganizationNameInput = {
  name: Scalars['String'];
};

export type ListUsersInOrganizationInput = {
  name?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  showActive?: Maybe<Scalars['Boolean']>;
};

export type ListUsersInOrganizationPayload = {
  __typename?: 'ListUsersInOrganizationPayload';
  count: Scalars['Int'];
  usersOrganizations: Array<Maybe<UserOrganization>>;
};

export type UserOrganization = {
  __typename?: 'UserOrganization';
  id: Scalars['String'];
  inviteStatus: OrganizationInviteStatus;
  inviteHash?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
  createdAt: Scalars['Datetime'];
  organization: Organization;
  user: User;
  active: Scalars['Boolean'];
  organizationRole: OrganizationRole;
};

export enum OrganizationInviteStatus {
  Refused = 'refused',
  Pendent = 'pendent',
  Accept = 'accept',
  Exited = 'exited',
}

export type FindUsersToOrganizationInput = {
  name: Scalars['String'];
};

export type FindUsersToOrganizationPayload = {
  __typename?: 'FindUsersToOrganizationPayload';
  user: User;
  inviteStatus?: Maybe<OrganizationInviteStatus>;
};

export type TeammatesCapacities = {
  __typename?: 'TeammatesCapacities';
  teammates: CapacitiesValues;
};

export type CapacitiesValues = {
  __typename?: 'CapacitiesValues';
  total: Scalars['Int'];
  used: Scalars['Int'];
};

export type ListAvailableUsersToServiceInput = {
  serviceName: ServiceName;
  name: Scalars['String'];
};

export enum ServiceName {
  Affiliate = 'affiliate',
  Teste = 'teste',
}

export type ListAffiliatesMembersInput = {
  name?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type ListAffiliatesMembersPayload = {
  __typename?: 'ListAffiliatesMembersPayload';
  count: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  affiliates: Array<Maybe<UserOrganizationService>>;
};

export type UserOrganizationService = {
  __typename?: 'UserOrganizationService';
  id: Scalars['String'];
  showFirstSteps: Scalars['Boolean'];
  service: Service;
  serviceRoles: ServiceRoles;
  active: Scalars['Boolean'];
  userOrganization?: Maybe<UserOrganization>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  bankData?: Maybe<UserBankValues>;
};

export type UserBankValues = {
  __typename?: 'UserBankValues';
  id: Scalars['String'];
  name: Scalars['String'];
  agency: Scalars['String'];
  account: Scalars['String'];
  accountDigit: Scalars['String'];
  document: Scalars['String'];
  brazilBank: BrazilBanks;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type BrazilBanks = {
  __typename?: 'BrazilBanks';
  id: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type GetUserInOrganizationServiceInput = {
  userOrganizationId: Scalars['String'];
};

export type GetUserOrganizationByServiceNameInput = {
  serviceName: ServiceName;
};

export type GetUserInOrganizationServiceByIdInput = {
  userOrganizationServiceId: Scalars['String'];
};

export type AffiliatesCapacities = {
  __typename?: 'AffiliatesCapacities';
  analyst: CapacitiesValues;
  sale?: Maybe<CapacitiesValues>;
};

export type VtexDepartmentsComissionsAdapted = {
  __typename?: 'VtexDepartmentsComissionsAdapted';
  id: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
  active: Scalars['Boolean'];
  percentage?: Maybe<Scalars['Int']>;
};

export type VtexAffiliateCommissionInput = {
  vtexDepartmentsIds: Array<Maybe<Scalars['String']>>;
  affiliateId: Scalars['String'];
};

export type VtexDepartmentsComissionsPercentage = {
  __typename?: 'VtexDepartmentsComissionsPercentage';
  vtexDepartmentId: Scalars['String'];
  percentage?: Maybe<Scalars['Int']>;
  payDay?: Maybe<Scalars['String']>;
};

export type TimeToPayCommission = {
  __typename?: 'TimeToPayCommission';
  id: Scalars['String'];
  days: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  createdAt: Scalars['Datetime'];
};

export type DefaultCommission = {
  __typename?: 'DefaultCommission';
  id: Scalars['String'];
  percentage: Scalars['Float'];
  updatedAt: Scalars['Datetime'];
  createdAt: Scalars['Datetime'];
};

export type ListAffiliateShorterUrlInput = {
  userOrganizationServiceId: Scalars['String'];
  serviceName: ServiceName;
};

export type UserOrganizationServiceRolesUrlShortener = {
  __typename?: 'UserOrganizationServiceRolesUrlShortener';
  id: Scalars['String'];
  shortenerUrl: ShortenerUrl;
  userOrganizationService: UserOrganizationService;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type ShortenerUrl = {
  __typename?: 'ShortenerUrl';
  id: Scalars['String'];
  originalUrl: Scalars['String'];
  shortUrl: Scalars['String'];
  urlCode: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type GetAllOrganizationOrdersInput = {
  limit?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Datetime']>;
  endDate?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
};

export enum OrderStatus {
  Approved = 'approved',
  Canceled = 'canceled',
  Reproved = 'reproved',
}

export type AllOrders = {
  __typename?: 'AllOrders';
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  count: Scalars['Int'];
  data: Array<Maybe<Order>>;
};

export type Order = {
  __typename?: 'Order';
  _id: Scalars['String'];
  isPaid?: Maybe<Scalars['Boolean']>;
  orderId: Scalars['String'];
  plugoneAffiliateStatus: PlugoneAffiliateStatus;
  creationDate: Scalars['String'];
  value: Scalars['Float'];
  affiliateInfo: AffiliateInfo;
  items: Array<Maybe<OrderItem>>;
  clientData: ClientData;
};

export enum PlugoneAffiliateStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Reproved = 'Reproved',
}

export type AffiliateInfo = {
  __typename?: 'AffiliateInfo';
  affiliateId: Scalars['String'];
  commission: AffiliateCommission;
};

export type AffiliateCommission = {
  __typename?: 'AffiliateCommission';
  amount: Scalars['Float'];
  payDay: Scalars['String'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
};

export type ClientData = {
  __typename?: 'ClientData';
  name?: Maybe<Scalars['String']>;
  document?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type GetOrganizationOrdersByAffiliateIdInput = {
  limit?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Datetime']>;
  endDate?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  status?: Maybe<OrderStatus>;
  serviceName: ServiceName;
};

export type GetOrganizationRevenueInput = {
  startDate?: Maybe<Scalars['Datetime']>;
  endDate?: Maybe<Scalars['Datetime']>;
};

export type OrganizationRevenue = {
  __typename?: 'OrganizationRevenue';
  amount: Scalars['Float'];
};

export type GetOrganizationAverageTicketInput = {
  startDate?: Maybe<Scalars['Datetime']>;
  endDate?: Maybe<Scalars['Datetime']>;
};

export type OrganizationAverageTicket = {
  __typename?: 'OrganizationAverageTicket';
  amount: Scalars['Float'];
};

export type GetOrganizationTotalOrdersInput = {
  startDate?: Maybe<Scalars['Datetime']>;
  endDate?: Maybe<Scalars['Datetime']>;
};

export type OrganizationTotalOrders = {
  __typename?: 'OrganizationTotalOrders';
  amount: Scalars['Int'];
};

export type GetOrganizationCommissionInput = {
  paid?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Datetime']>;
  endDate?: Maybe<Scalars['Datetime']>;
};

export type OrganizationCommission = {
  __typename?: 'OrganizationCommission';
  data: Array<Maybe<OrganizationCommissionItem>>;
};

export type OrganizationCommissionItem = {
  __typename?: 'OrganizationCommissionItem';
  _id: Scalars['String'];
  affiliateInfo: AffiliateInfo;
};

export type GetOrganizationTotalOrdersByAffiliateInput = {
  startDate?: Maybe<Scalars['Datetime']>;
  endDate?: Maybe<Scalars['Datetime']>;
  serviceName: ServiceName;
};

export type GetOrganizationCommissionByAffiliateInput = {
  paid?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Datetime']>;
  endDate?: Maybe<Scalars['Datetime']>;
  serviceName?: Maybe<ServiceName>;
};

export type UserOrganizationPermissionsInput = {
  name: PermissionName;
};

export enum PermissionName {
  Settings = 'settings',
  Members = 'members',
  Integrations = 'integrations',
  Affiliate = 'affiliate',
}

export type OrganizationPermissions = {
  __typename?: 'OrganizationPermissions';
  id: Scalars['String'];
  permissionName: PermissionName;
  organizationRoleName: OrganizationRoleName;
  grant: PermissionGrant;
};

export enum PermissionGrant {
  Write = 'write',
  Read = 'read',
  Hide = 'hide',
}

export type UserServicePermissionsInput = {
  name?: Maybe<ServicePermissionName>;
  serviceName: ServiceName;
};

export enum ServicePermissionName {
  Commission = 'commission',
  Orders = 'orders',
  GenerateLink = 'generateLink',
  Payments = 'payments',
  Members = 'members',
}

export type ServicePermissions = {
  __typename?: 'ServicePermissions';
  id: Scalars['String'];
  permissionName: ServicePermissionName;
  serviceRoleName: ServiceRolesName;
  grant: PermissionGrant;
  service: Service;
};

export enum ServiceRolesName {
  Admin = 'ADMIN',
  Responsible = 'RESPONSIBLE',
  Analyst = 'ANALYST',
  Sale = 'SALE',
}

export type MenuTree = {
  __typename?: 'MenuTree';
  group: Scalars['String'];
  items: Array<Maybe<MenuItem>>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<MenuChildren>>>;
};

export type MenuChildren = {
  __typename?: 'MenuChildren';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<SubMenuChildren>>>;
};

export type SubMenuChildren = {
  __typename?: 'SubMenuChildren';
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GetBrazilBanksInput = {
  name?: Maybe<Scalars['String']>;
};

export type Plan = {
  __typename?: 'Plan';
  id: Scalars['String'];
  interval: Scalars['Int'];
  intervalType: Scalars['String'];
  name: Scalars['String'];
  payableWith: Scalars['String'];
  price: Scalars['Float'];
  bestPrice: Scalars['Boolean'];
  planRules: Array<Maybe<PlanRules>>;
};

export type PlanRules = {
  __typename?: 'PlanRules';
  serviceName: Scalars['String'];
  rules: Rules;
};

export type Rules = {
  __typename?: 'Rules';
  maxTransactionTax: Scalars['Int'];
  maxTeammates: Scalars['Int'];
  maxAnalysts: Scalars['Int'];
  maxSales: Scalars['Int'];
  support: Scalars['String'];
  training: Scalars['Boolean'];
  sso: Scalars['Boolean'];
};

export type OrganizationCustomerPayment = {
  __typename?: 'OrganizationCustomerPayment';
  id: Scalars['String'];
  description: Scalars['String'];
  itemType: Scalars['String'];
  data: PaymentMethodData;
};

export type PaymentMethodData = {
  __typename?: 'PaymentMethodData';
  brand: Scalars['String'];
  holder: Scalars['String'];
  lastDigits: Scalars['String'];
};

export type GetSubscriptionByOrganizationIdInput = {
  organizationId: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  id: Scalars['String'];
  suspended: Scalars['Boolean'];
  planIdentifier: Scalars['String'];
  priceCents: Scalars['Int'];
  currency: Scalars['String'];
  expiresAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  customerPaymentInfos?: Maybe<OrganizationCustomerPayment>;
  cycledAt: Scalars['String'];
  invoiceData?: Maybe<InvoiceData>;
  payableWith: Scalars['String'];
  planName: Scalars['String'];
  active: Scalars['Boolean'];
  recentInvoices: Array<Maybe<IuguRecentInvoices>>;
};

export type InvoiceData = {
  __typename?: 'InvoiceData';
  cpfCnpj: Scalars['String'];
  zipCode: Scalars['String'];
  number: Scalars['String'];
  complement?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  district: Scalars['String'];
  street: Scalars['String'];
};

export type IuguRecentInvoices = {
  __typename?: 'IuguRecentInvoices';
  id: Scalars['String'];
  dueDate: Scalars['String'];
  status: Scalars['String'];
  total: Scalars['String'];
  secureUrl: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  createIntegration?: Maybe<Scalars['Boolean']>;
  signUp: User;
  userVerifyEmail?: Maybe<Scalars['Boolean']>;
  userRecoveryPassword?: Maybe<Scalars['Boolean']>;
  userPasswordChange?: Maybe<Scalars['Boolean']>;
  signIn: SignUpPayload;
  inviteAffiliate: Array<Maybe<UserOrganization>>;
  inviteTeammates: Array<Maybe<UserOrganization>>;
  handleTeammatesActivity: UserOrganization;
  handleServiceMembersActivity: Scalars['Boolean'];
  reinviteServiceMember: Scalars['Boolean'];
  createOrganization: Organization;
  setCurrentOrganization: Scalars['Boolean'];
  organizationUploadImage: Organization;
  inviteUserToOrganization: Scalars['Boolean'];
  responseOrganizationInvite: ResponseOrganizationInvitePayload;
  handleUserPermissionInOrganization: UserOrganizationRole;
  createServiceInOrganization: Scalars['Boolean'];
  addUserInOrganizationService: UserOrganizationService;
  handleServiceMembersRole: UserOrganizationService;
  inativeUserFromServiceOrganization: UserOrganizationService;
  handleOrganizationVtexCommission: VtexDepartmentsComissions;
  verifyAndAttachVtexSecrets: Scalars['Boolean'];
  affiliateGenerateShortenerUrl: UserOrganizationServiceRolesUrlShortener;
  generateSalesJwt: SalesSession;
  createAffiliateBankValues: UserOrganizationService;
  generateSalesShorten: ShortenerUrl;
  paidAffiliateCommission?: Maybe<Scalars['Boolean']>;
  handleTimeToPayCommission: TimeToPayCommission;
  handleDefaultCommission: DefaultCommission;
  sendRecurrencyTransaction: Scalars['Boolean'];
  updateRecurrencyTransaction: Subscription;
  cancelRecurrencyTransaction: Subscription;
  activateRecurrencyTransaction: Subscription;
  createOrganizationCustomer: Scalars['Boolean'];
  editOrganizationCustomer: Scalars['Boolean'];
  createOrganizationCustomerPayment: Scalars['Boolean'];
  removeOrganizationCustomerPayment: Scalars['Boolean'];
};

export type MutationCreateIntegrationArgs = {
  input: CreateIntegrationInput;
};

export type MutationSignUpArgs = {
  input: SignUpInput;
};

export type MutationUserVerifyEmailArgs = {
  input: UserVerifyEmailInput;
};

export type MutationUserRecoveryPasswordArgs = {
  input: UserRecoveryPasswordInput;
};

export type MutationUserPasswordChangeArgs = {
  input: UserPasswordChangeInput;
};

export type MutationSignInArgs = {
  input: SignInInput;
};

export type MutationInviteAffiliateArgs = {
  input: InviteAffiliateInput;
};

export type MutationInviteTeammatesArgs = {
  input: InviteTeammatesInput;
};

export type MutationHandleTeammatesActivityArgs = {
  input: HandleTeammatesActivityInput;
};

export type MutationHandleServiceMembersActivityArgs = {
  input: HandleServiceMembersActivityInput;
};

export type MutationReinviteServiceMemberArgs = {
  input: ReinviteServiceMemberInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationSetCurrentOrganizationArgs = {
  input: SetCurrentOrganizationInput;
};

export type MutationOrganizationUploadImageArgs = {
  input: OrganizationUploadImageInput;
};

export type MutationInviteUserToOrganizationArgs = {
  input: InviteUserToOrganizationInput;
};

export type MutationResponseOrganizationInviteArgs = {
  input: ResponseOrganizationInviteInput;
};

export type MutationHandleUserPermissionInOrganizationArgs = {
  input: HandleUserPermissionInOrganizationInput;
};

export type MutationCreateServiceInOrganizationArgs = {
  input: CreateServiceInOrganizationInput;
};

export type MutationAddUserInOrganizationServiceArgs = {
  input: AddUserInOrganizationServiceInput;
};

export type MutationHandleServiceMembersRoleArgs = {
  input: HandleServiceMembersRoleInput;
};

export type MutationInativeUserFromServiceOrganizationArgs = {
  input: InativeUserFromServiceOrganizationInput;
};

export type MutationHandleOrganizationVtexCommissionArgs = {
  input: HandleOrganizationVtexCommissionInput;
};

export type MutationVerifyAndAttachVtexSecretsArgs = {
  input: VerifyAndAttachVtexSecretsInput;
};

export type MutationAffiliateGenerateShortenerUrlArgs = {
  input: AffiliateGenerateShortenerUrlInput;
};

export type MutationGenerateSalesJwtArgs = {
  input: GenerateSalesJwtInput;
};

export type MutationCreateAffiliateBankValuesArgs = {
  input: CreateAffiliateBankValuesInput;
};

export type MutationGenerateSalesShortenArgs = {
  input: GenerateSalesShortenInput;
};

export type MutationPaidAffiliateCommissionArgs = {
  input: PaidAffiliateCommissionInput;
};

export type MutationHandleTimeToPayCommissionArgs = {
  input: HandleTimeToPayCommissionInput;
};

export type MutationHandleDefaultCommissionArgs = {
  input: HandleDefaultCommissionInput;
};

export type MutationSendRecurrencyTransactionArgs = {
  input: SendRecurrencyTransactionInput;
};

export type MutationUpdateRecurrencyTransactionArgs = {
  input: UpdateRecurrencyTransactionInput;
};

export type MutationCreateOrganizationCustomerArgs = {
  input: CreateOrganizationCustomerInput;
};

export type MutationEditOrganizationCustomerArgs = {
  input: EditOrganizationCustomerInput;
};

export type MutationCreateOrganizationCustomerPaymentArgs = {
  input: CreateOrganizationCustomerPaymentInput;
};

export type MutationRemoveOrganizationCustomerPaymentArgs = {
  input: RemoveOrganizationCustomerPaymentInput;
};

export type CreateIntegrationInput = {
  secrets: IntegrationSecrets;
  type: Integrations;
};

export type IntegrationSecrets = {
  xVtexApiAppKey?: Maybe<Scalars['String']>;
  xVtexApiAppToken?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  appKey?: Maybe<Scalars['String']>;
};

export type SignUpInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UserVerifyEmailInput = {
  verificationHash: Scalars['String'];
};

export type UserRecoveryPasswordInput = {
  email: Scalars['String'];
};

export type UserPasswordChangeInput = {
  hash: Scalars['String'];
  password: Scalars['String'];
};

export type SignInInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignUpPayload = {
  __typename?: 'SignUpPayload';
  token: Scalars['String'];
  user: User;
};

export type InviteAffiliateInput = {
  users: Array<AffiliateUsers>;
};

export type AffiliateUsers = {
  email: Scalars['String'];
  role: ServiceRolesName;
};

export type InviteTeammatesInput = {
  emails: Array<Scalars['String']>;
};

export type HandleTeammatesActivityInput = {
  userOrganizationId: Scalars['String'];
  activity: Scalars['Boolean'];
};

export type HandleServiceMembersActivityInput = {
  userOrganizationId: Scalars['String'];
  activity: Scalars['Boolean'];
  service: ServiceName;
};

export type ReinviteServiceMemberInput = {
  userOrganizationId: Scalars['String'];
};

export type CreateOrganizationInput = {
  organization: OrganizationInput;
  additionalInfos: AdditionalInfos;
};

export type OrganizationInput = {
  name: Scalars['String'];
  phone: Scalars['String'];
  contactEmail: Scalars['String'];
};

export type AdditionalInfos = {
  segment: Scalars['String'];
  resellersEstimate: Scalars['Int'];
  reason: Scalars['String'];
  plataform: Scalars['String'];
};

export type SetCurrentOrganizationInput = {
  organizationId?: Maybe<Scalars['String']>;
};

export type OrganizationUploadImageInput = {
  mimetype?: Maybe<Scalars['String']>;
  data: Scalars['Upload'];
  organizationId: Scalars['String'];
};

export type InviteUserToOrganizationInput = {
  users: Array<Maybe<InviteUsersInput>>;
};

export type InviteUsersInput = {
  id?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  role?: Maybe<OrganizationRoleName>;
  services?: Maybe<Array<ServiceInput>>;
};

export type ServiceInput = {
  name?: Maybe<ServiceName>;
  role?: Maybe<ServiceRolesName>;
};

export type ResponseOrganizationInviteInput = {
  inviteHash: Scalars['String'];
  response: OrganizationInviteStatus;
};

export type ResponseOrganizationInvitePayload = {
  __typename?: 'ResponseOrganizationInvitePayload';
  status: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
};

export type HandleUserPermissionInOrganizationInput = {
  userId: Scalars['String'];
  permission: OrganizationRoleName;
};

export type UserOrganizationRole = {
  __typename?: 'UserOrganizationRole';
  id: Scalars['String'];
  userOrganization: UserOrganization;
  organizationRole: OrganizationRole;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

export type CreateServiceInOrganizationInput = {
  serviceId: Scalars['String'];
  organizationId: Scalars['String'];
};

export type AddUserInOrganizationServiceInput = {
  userId: Scalars['String'];
  serviceName: ServiceName;
};

export type HandleServiceMembersRoleInput = {
  input: {
    serviceName: ServiceName;
    serviceRole: ServiceRolesName;
    userOrganizationServiceRoleId: Scalars['String'];
  };
};

export type InativeUserFromServiceOrganizationInput = {
  serviceName: ServiceName;
  userId: Scalars['String'];
};

export type HandleOrganizationVtexCommissionInput = {
  vtexDepartmentId: Scalars['String'];
  vtexCommissionPercentage: Scalars['Int'];
  active: Scalars['Boolean'];
};

export type VtexDepartmentsComissions = {
  __typename?: 'VtexDepartmentsComissions';
  id: Scalars['String'];
  vtexDepartmentId: Scalars['String'];
  organizationId: Scalars['String'];
  active: Scalars['Boolean'];
  vtexCommissionPercentage: Scalars['Int'];
  updatedAt: Scalars['Datetime'];
  createdAt: Scalars['Datetime'];
};

export type VerifyAndAttachVtexSecretsInput = {
  xVtexApiAppKey: Scalars['String'];
  xVtexApiAppToken: Scalars['String'];
  accountName: Scalars['String'];
};

export type AffiliateGenerateShortenerUrlInput = {
  originalUrl: Scalars['String'];
  serviceName: ServiceName;
};

export type GenerateSalesJwtInput = {
  email: Scalars['String'];
  organizationId: Scalars['String'];
  serviceName: ServiceName;
};

export type SalesSession = {
  __typename?: 'SalesSession';
  salesId: Scalars['String'];
  vtexSalePixelJwt: Scalars['String'];
};

export type CreateAffiliateBankValuesInput = {
  name: Scalars['String'];
  agency: Scalars['String'];
  account: Scalars['String'];
  accountDigit: Scalars['String'];
  document: Scalars['String'];
  brazilBankId: Scalars['String'];
  serviceName: ServiceName;
};

export type GenerateSalesShortenInput = {
  url: Scalars['String'];
};

export type PaidAffiliateCommissionInput = {
  affiliateCommissionSetPaid: Array<Maybe<AffiliateCommissionSetPaid>>;
};

export type AffiliateCommissionSetPaid = {
  affiliateId: Scalars['String'];
  orderId: Scalars['String'];
};

export type HandleTimeToPayCommissionInput = {
  days: Scalars['Int'];
};

export type HandleDefaultCommissionInput = {
  percentage: Scalars['Float'];
};

export type SendRecurrencyTransactionInput = {
  planIdentifier: Scalars['String'];
  payableWith: PayableWith;
  marketingData?: {
    utmSource?: string;
    utmCampaign?: string;
  };
};

export enum PayableWith {
  BankSlip = 'bank_slip',
  CreditCard = 'credit_card',
}

export type UpdateRecurrencyTransactionInput = {
  planIdentifier: Scalars['String'];
  payableWith: PayableWith;
};

export type CreateOrganizationCustomerInput = {
  zipCode: Scalars['String'];
  number: Scalars['String'];
  cpfCnpj: Scalars['String'];
  complement?: Maybe<Scalars['String']>;
};

export type EditOrganizationCustomerInput = {
  zipCode: Scalars['String'];
  number: Scalars['String'];
  cpfCnpj: Scalars['String'];
  complement?: Maybe<Scalars['String']>;
};

export type CreateOrganizationCustomerPaymentInput = {
  description?: Maybe<Scalars['String']>;
  isDefault?: Boolean
  token: Scalars['String'];
};

export type RemoveOrganizationCustomerPaymentInput = {
  paymentId: Scalars['String'];
};

export type UserError = {
  __typename?: 'UserError';
  message: Scalars['String'];
  userId: Scalars['String'];
};

export type GetAllCommissionsInput = {
  limit?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Datetime']>;
  endDate?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
};

export enum EChannel {
  AffiliateLinkGenerator = 'affiliate_link_generator',
  Coupon = 'coupon',
  InsideSales = 'affiliate_inside_sales',
  AffiliateStore = 'affiliate_store',
  External = 'external'
}

export enum EWithdrawStatus {
  Pending = 'Pending',
  Scheduled = 'Scheduled',
  Requested = 'Requested',
  Failed = 'Failed',
  Canceled = 'Canceled',
  Success = 'Success',
  PaymentConfirmed = 'PaymentConfirmed'
}

export interface WithdrawEvents {
  type: EWithdrawStatus
  at: Date
}

export interface WithdrawError {
  code: string | number
  message: string
}
