import React from 'react';
import styled from 'styled-components';
import _Drawer from '@material-ui/core/Drawer';
import { Box } from 'rebass';

import SideViewHeader from './side-view-header';
import SideViewContent from './side-view-content';
import MediaQuery from 'react-responsive';

/**
 * This orrendous z-ndex values are needed beacause Intercom
 * sets it's widget z-index to a value of 2147483000 and we want to render
 * our side-view on top of it because often we have buttons and other
 * ui on the same position.
 */
const Drawer = styled(_Drawer)<Partial<SideViewProps>>`
  z-index: 2147483001 !important;
  // height: ${({ height }): string => height ?? '100%'}!important;

  & .MuiBackdrop-root {
    cursor: pointer;
  }

  & .MuiDrawer-paper {
    z-index: 2147483002;
    height: ${({ height }): string => height ?? '100%'}!important;
    width: ${({ width }): string => width ?? '100%'}!important;
    border-top-right-radius: ${({ borderradiustopright }): string => borderradiustopright ?? '0'};
    border-top-left-radius: ${({ borderradiustopleft }): string => borderradiustopleft ?? '0'};
    border-bottom-right-radius: ${({ borderradiusbottomright }): string =>
      borderradiusbottomright ?? '0'};
    border-bottom-left-radius: ${({ borderradiusbottomleft }): string =>
      borderradiusbottomleft ?? '0'};
  }
`;

export type iPositionTypes = 'bottom' | 'top' | 'left' | 'right' | undefined;

export type SideViewProps = {
  children: React.ReactNode;
  width?: string;
  height?: string;
  isOpenSideView: boolean;
  position?: iPositionTypes;
  borderradiustopright?: string;
  borderradiustopleft?: string;
  borderradiusbottomright?: string;
  borderradiusbottomleft?: string;
} & SideViewContextType;

type SideViewContextType = {
  onCloseSideView: () => void;
};

type ISideView<P> = React.FunctionComponent<P> & {
  Header: typeof SideViewHeader;
  Content: typeof SideViewContent;
};

export const SideViewContext = React.createContext<SideViewContextType | null>(null);

export function useSideViewContext() {
  const context = React.useContext(SideViewContext);
  if (!context) {
    throw new Error(
      `SideView compound components cannot be rendered outside the SideView component`,
    );
  }
  return context;
}

const SideView: ISideView<SideViewProps> = ({
  children,
  onCloseSideView,
  isOpenSideView,
  position,
  ...props
}) => {
  const value = { onCloseSideView };
  return (
    <MediaQuery maxWidth={768}>
      {matches => (
        <SideViewContext.Provider value={value}>
          <Drawer
            anchor={position ?? 'right'}
            open={isOpenSideView}
            onClose={onCloseSideView}
            borderradiustopright={props.borderradiustopright ?? '0'}
            borderradiustopleft={props.borderradiustopleft ?? '0'}
            borderradiusbottomright={props.borderradiusbottomright ?? '0'}
            borderradiusbottomleft={props.borderradiusbottomleft ?? '0'}
            height={props.height ?? '100%'}
            width={matches ? '100%' : '450px'}
          >
            <Box
              width={matches ? '100%' : '450px'}
              maxWidth={matches ? '100%' : '450px'}
              height={props.height ?? '100%'}
            >
              {children}
            </Box>
          </Drawer>
        </SideViewContext.Provider>
      )}
    </MediaQuery>
  );
};

SideView.Header = SideViewHeader;
SideView.Content = SideViewContent;

export default SideView;
