import { Integrations } from '@plug/graphql/types';
import { ENVIRONMENT } from './env';

export const APP_NAME = 'plugOne';

export const TOKEN_LOCAL_STORAGE_KEY = `${APP_NAME}_token`;
export const USER_LOCAL_STORAGE_KEY = `${APP_NAME}_user`;
export const CURRENT_ORGANIZATION_STORAGE_KEY = `${APP_NAME}_current_organization_id`;
export const USER_ORGANIZATION_PERMISSIONS_STORAGE_KEY = `${APP_NAME}_organization_permissions`;
export const USER_SERVICES_PERMISSIONS_STORAGE_KEY = `${APP_NAME}_services_permissions`;
export const ORGANIZATION_RULES_PERMISSION_STORAGE_KEY = `${APP_NAME}_organization_rules_permission`;

export const CACHE_UPDATE_DATE_STORAGE_KEY = `${APP_NAME}_cache_updated_at`;
export const CACHE_MINIMUM_UPDATE_INTERVAL = 3 * 60 * 1000;

export const APOLLO_SCHEMA_VERSION_STORAGE_KEY = `${APP_NAME}_apollo_schema_version`;
export const APOLLO_DEFAULT_SCHEMA_VERSION = '1';
export const APOLLO_AUTH_HEADER_KEY = 'x-api-token';
export const APOLLO_FETCH_POLICY = 'cache-and-network';
export const ENCRYPTION_LS_KEY = 'pLUG0n3';

export const DEFAULT_HUBLY_RETAILER_LOGO_URL =
  'https://plugone-production.nyc3.digitaloceanspaces.com/assets/monitfy-avatar.png';

export const AFFILIATE_SAAS_SIGNATURES = '/affiliate/signatures';
export const AFFILIATE_ORDER_COMISSION = '/affiliate/commission';
export const AFFILIATE_INSIDE_SALES = '/affiliate/inside-sales';
export const AFFILIATE_ABANDONED_CART = '/affiliate/abandoned-carts';

export enum ORGANIZATION_ROUTES {
  AFFILIATE_SAAS_SIGNATURES = '/affiliate/signatures',
  AFFILIATE_ORDER_COMISSION = '/affiliate/commission',
  AFFILIATE_ORDER = '/affiliate/orders',
  AFFILIATE_PAYMENT = '/affiliate/payments',
  AFFILIATE_INSIDE_SALES = '/affiliate/inside-sales',
  AFFILIATE_MEMBERS = '/affiliate/members',
  AFFILIATE_GENERATE_LINK = '/affiliate/link-generator',
  AFFILIATE_SHOWCASE = '/affiliate/showcase',
  AFFILIATE_ABANDONED_CART = '/affiliate/abandoned-carts',
  INTEGRATION = '/integrations',
  OVERVIEW = '/overview',
  SETTINGS = '/settings',
}

export const WHITELABEL_LS_KEY = 'WHITE_LABEL_INFO';

export const LI_ALLOWED_ORGANIZATION_IDS = [
  '7c797775-f56e-43af-98b3-13d4aa5ac6cb',
  '2cb7abe0-59a7-4aa7-9e03-9726fadfdbf7',
  // '3e4b948d-79cf-4a1b-a685-d839fcd6b1ab',
];

export const OLIST_ORGANIZATION_IDS = [
  'f8bb278d-405f-4723-b12b-9a924bec7829',
  '3c77e42d-65ba-4d55-8a50-aebcc84d853e',
];

export const NOT_ALLOWED_SHOWCASE_ORGANIZATION = [
  'a1161753-fe07-4bfa-8d4c-c8546dfa9e95',
  '0ddd1b49-b9af-44f0-b14d-22d66db712b7', // FEBRACIS
];

export const OLIST_HOST_NAME = [
  // 'localhost',
  // 'localhost:3000',
  //'gohubly-dashboard.web.app',
  'parceiros.olist.com',
];

export const MARY_KAY_WHITELABEL = [
  // 'localhost',
  // 'localhost:3000',
  // 'gohubly-dashboard.web.app',
  // 'app-staging.monitfy.com',
  'mk-staging.monitfy.com',
  'consultora.marykay.com.br',
];

export const ESPACO_SMART_WHITE_LABEL = ['parceiro.espacosmart.com.br'];

export const EFIZI_HOST_NAME = [
  // 'localhost',
  'parceiro.efizi.com.br',
];

export const MK_USA_POC_WHITELABEL_IDS = [
  'dff3ec64-4798-4e72-99a2-13fa37474aa8',
  //'f13f604a-826c-4ca5-a550-b71953136917',
];

export const ALLOW_MULTILEVEL_APP = [
  'localhost',
  'influencer.anapaulacarvalho.com',
  'app-staging.monitfy.com',
];

export const JS_WEBHOOK_CODE = `
  const express = require('express')

  const app = express()

  app.use(express.json())

  app.post('/webhook', (req, res) => {
    /* iremos enviar uma requisição inicial para verificar o host
      e esperamos uma resposta com status 200
    */
    if (req.body?.ping) return res.status(200).send('received')
    
    // tratar os eventos
    { TREAT_EVENTS }
    
    res.status(200).send('received')
  })

  app.listen(3000, () => console.log('server created...'))
`;

export const LI_COMISSION_TYPE = {
  signature_created: 'Indicação de loja',
  first_quarter: 'Assinatura em até 30 dias',
  last_quarters: 'Bônus 365 dias de assinatura',
} as { [key: string]: string };

export const LI_TAG_MANAGER = {
  head: `
  <!-- Google Tag Manager -->
  <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-5ZPJ4T');</script>
  <!-- End Google Tag Manager -->  
  `,
  body: `
  <!-- Google Tag Manager (noscript) -->
  <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5ZPJ4T"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  <!-- End Google Tag Manager (noscript) -->  
  `,
};

export const ORGANIZATION_TYPE_SEGMENTS = [
  'Acessórios Automotivos',
  'Alimentos e Bebidas',
  'Arte e Antiguidades',
  'Artesanato',
  'Artigos promocionais',
  'Artigos Religiosos',
  'Bebês e Cia',
  'Bebidas Alcoólicas',
  'Blu-Ray, DVD, CD e VHS',
  'Brinquedos e Colecionáveis',
  'Casa e Decoração',
  'Construção e Ferramentas',
  'Cosméticos, Perfumaria e Cuidados Pessoais',
  'Eletrodomésticos',
  'Eletrônicos',
  'Equipamentos para Laboratório',
  'Esporte e Lazer',
  'Festas e Eventos',
  'Fitness e Suplementos',
  'Fotografia',
  'Games',
  'Gráfica',
  'Informática',
  'Ingressos',
  'Instrumentos Musicais',
  'Livros e Revistas',
  'Loja de departamentos',
  'Moda e Acessórios',
  'Móveis',
  'Papelaria e Escritório',
  'Pet Shop',
  'Presentes, Flores e Cestas',
  'Prestação de Serviço',
  'Relojoaria e Joalheria',
  'Saúde',
  'Sex Shop',
  'Telefonia e Celulares',
  'Viagens e Turismo',
  'Outro',
];

export const ORGANIZATION_CUSTOMIZATION_KEY = 'ORGANIZATION_CUSTOMIZATION';

export const UPLOAD_SIZE_LIMIT = 5242880 / (1024 * 1024);

export const LOCAL_CART_INFO_KEY = 'checkout_cart';

export const WHITE_LABEL_TITLES = {
  'influencer.anapaulacarvalho.com': 'Ana Paula Carvalho',
  'parceiros.olist.com': 'Parceiros Tiny',
  'consultora.marykay.com.br': 'Minha Loja | MARY KAY A UM CLIQUE',
  'parceiro.efizi.com.br': 'Efizi',
  'parceiro.espacosmart.com.br': 'Seja Smart - Comunidade de Construção a Seco',
  'afiliados.madesa.com': 'Madesa Móveis',
  'edvitrine.lojafebracis.com.br': 'Plataforma ED Febracis',
  'parceira.marimariamakeup.com': 'Parceira Mari Maria Makeup',
  'parceiros.lindacasa.com.br': 'Parceiros Linda Casa',
  'parceiro.lojakingspan.com': 'Parceiro Kingspan',
};

export const AFFILIATE_STORE_ALLOW_PREVIEW_DOMAINS = [...MARY_KAY_WHITELABEL];

export const MARY_KAY_CODE_BANKS = [
  '001',
  '003',
  '004',
  '021',
  '025',
  '033',
  '041',
  '047',
  '069',
  '070',
  '077',
  '084',
  '085',
  '094',
  '097',
  '099',
  '104',
  '107',
  '133',
  '136',
  '197',
  '212',
  '218',
  '237',
  '246',
  '260',
  '301',
  '318',
  '323',
  '336',
  '341',
  '376',
  '389',
  '422',
  '600',
  '604',
  '623',
  '637',
  '654',
  '655',
  '743',
  '745',
  '748',
  '755',
  '756',
  '757',
];

export const FEATURES_LABEL: Record<IOrganizationNewRulesPermissions & Integrations, string> = {
  vtex: 'Integração nativa VTEX',
  loja_integrada: 'Integração nativa Loja Integrada',
  yampi: 'Integração nativa Yampi',
  tray: 'Integração nativa Tray',
  transactional_mail_basic: 'E-mails transacionais',
  transactional_mail_personalized: 'E-mails transacionais personalizados',
  hierarchy_administrators: 'Administradores com hierarquia',
  resellers: 'Revendedores',
  commissions: 'Comissionamento geral',
  commissions_goals: 'Bônus por Perfomance',
  commissions_settings: 'Comissão Personalizada',
  digital_showcase: 'Vitrines digitais personalizadas',
  report_finantial: 'Relatório financeiro',
  report_orders: 'Relatório de pedidos',
  report_commissions: 'Relatório de comissões',
  hubly_store: 'Loja de Aplicativos Monitfy',
  dashboard: 'Resumo e relatório dos resultados alcançados no programa',
  inside_sales: 'Carrinhos personalizados para compartilhar',
  shared_links: 'Links exclusivos para cada afiliado',
  implemented_strategy_cs: 'Implementação de estratégias',
  support_ticket: 'Tickets de suporte',
  support_chat: 'Suporte via chat',
  support_whatsapp: 'Suporte via WhatsApp',
  support_video: 'Suporte via vídeo',
  whitelabel: 'Whitelabel',
  whitelabel_personalized: 'Whitelabel personalizado',
  webhook: 'Webhook',
  public_organization: 'Organização pública',
  layout_customization: 'Customização da interface',
  api: 'API',
};

export const FEATURES_TOOLTIP: Record<IOrganizationNewRulesPermissions & Integrations, string> = {
  vtex: 'Integração nativa VTEX',
  loja_integrada: 'Integração nativa Loja Integrada',
  transactional_mail_basic: 'E-mails transacionais',
  transactional_mail_personalized: 'E-mails transacionais personalizados',
  hierarchy_administrators: 'Administradores com hierarquia',
  resellers: 'Revendedores',
  commissions: 'Comissionamento geral',
  commissions_goals: 'Bônus por Perfomance',
  commissions_settings: 'Comissão Personalizada',
  digital_showcase: 'Vitrines digitais personalizadas',
  report_finantial: 'Relatório financeiro',
  report_orders: 'Relatório de pedidos',
  report_commissions: 'Relatório de comissões',
  hubly_store: 'Loja de Aplicativos Monitfy',
  dashboard: 'Resumo e relatório dos resultados alcançados no programa',
  inside_sales: 'Carrinhos personalizados para compartilhar',
  shared_links: 'Links exclusivos para cada afiliado',
  implemented_strategy_cs: 'Implementação de estratégias',
  support_ticket: 'Tickets de suporte',
  support_chat: 'Suporte via chat',
  support_whatsapp: 'Suporte via WhatsApp',
  support_video: 'Suporte via vídeo',
  whitelabel: 'Whitelabel',
  whitelabel_personalized: 'Whitelabel personalizado',
  webhook: 'Webhook',
  public_organization: 'Organização pública',
  layout_customization: 'Customização da interface',
  api: 'API',
};

export const DOMAIN =
  ENVIRONMENT === 'staging' && !MARY_KAY_WHITELABEL.includes(window.location.hostname)
    ? null
    : window.location.hostname;

export const I18N_NAMESPACES = {
  TRANSLATION: 'translation',
  LINK_GENERATOR: 'link-generator',
  COUPON: 'coupon',
  HUBLY_FORM: 'hubly-form',
  INSIDE_SALES: 'inside-sales',
  MEMBERS: 'members',
  ORDERS: 'orders',
  DIGITAL_SHOWCASE: 'digital-showcase',
  COMMISSIONS: 'commission',
  SETTINGS: 'settings',
  DOMAIN,
};

export const NEW_IUGU_PLANS_IDS = [
  'plan_starter',
  'plan_essential',
  'plan_pro',
  'plan_enterprise',
  'plan_starter_anual',
  'plan_essential_anual',
  'plan_pro_anual',
  'plan_enterprise_anual',
];

export const BLOCK_SUPPORT = !['app.monitfy.com', 'app-staging.monitfy.com'].includes(
  window.location.hostname,
);
