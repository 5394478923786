import gql from 'graphql-tag';

export const LIST_MY_ORGANIZATIONS = gql`
  query listMyOrganizations {
    listMyOrganizations {
      id
      contactEmail
      name
      active
      logo
      slug
      organizationRole {
        name
      }
    }
  }
`;

export type listMyOrganizationsQuery = {
  listMyOrganizations: Organization[];
};
