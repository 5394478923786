import { Countdown } from '@gohubly/design-system';
import getCurrentOrganization from '@plug/helpers/get-current-organization';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCountdownContext } from './context';
import * as css from './style';
import jsCookie from 'js-cookie';
import { ENVIRONMENT } from '@plug/config/env';
import { MARY_KAY_WHITELABEL } from '@plug/config/consts';
import useIsOrganizationAdmin from '@plug/hooks/use-is-organization-admin';
import isDesktop from '@plug/helpers/is-desktop';
import { useAuthContext } from '@plug/contexts/auth-context';
import { Integrations } from '@plug/graphql/types';
import { useMediaQuery } from 'react-responsive';
import { SHOWCASE_PATH } from '@plug/domains/organization/domains/affiliate/routing';

const CountdownContainer: React.FC = ({ children }): JSX.Element => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [countdown, setCountdown] = useState(0);
  const { show, toggle } = useCountdownContext();
  const { currentOrganization } = useAuthContext();
  const isOrganizationAdmin = useIsOrganizationAdmin();

  const isInitialShowcasePage = window.location.pathname.endsWith('/showcase');
  const isShowcaseTemplatePage = window.location.pathname.includes('/showcase/template');

  const isMaryKayAndShowCaseAdmin =
    (currentOrganization?.integration?.subtype === 'vtex_io' ||
      currentOrganization?.integration?.type === Integrations.Yampi) &&
    !isInitialShowcasePage &&
    !isShowcaseTemplatePage &&
    window.location.pathname.startsWith(
      SHOWCASE_PATH.replace(':organizationSlug', currentOrganization?.slug),
    ) &&
    isOrganizationAdmin &&
    isDesktop;

  // useEffect(() => {
  //   const freeTrialExpires = currentOrganization?.freeTrialExpires
  //     ? moment(currentOrganization?.freeTrialExpires)
  //     : null;
  //   const now = moment();
  //   const cookie = jsCookie.get('countdown');

  //   if (cookie === 'true') return;

  //   if (freeTrialExpires) {
  //     const difference = Math.round(freeTrialExpires.diff(now, 'days', true));

  //     if (difference <= 0) return;

  //     setCountdown(difference);

  //     setTimeout(() => {
  //       if (!show) toggle();
  //       jsCookie.set('countdown', 'true', {
  //         expires: 1,
  //       });
  //     }, 1000);
  //   }
  // }, [currentOrganization, show, toggle]);

  return (
    <css.Container isMaryKayAndShowCaseAdmin={isMaryKayAndShowCaseAdmin}>
      {ENVIRONMENT !== 'production' && (
        <css.AbsoluteContainer show={show}>
          <Countdown countdown={countdown} onCloseClick={(): void => toggle()} />
        </css.AbsoluteContainer>
      )}
      {children}
    </css.Container>
  );
};

export default CountdownContainer;
