import styled from 'styled-components';

export const Container = styled.div<{ isMaryKayAndShowCaseAdmin?: boolean }>`
  display: flex;
  flex-direction: column;

  position: relative;

  width: ${({ isMaryKayAndShowCaseAdmin }): string =>
    isMaryKayAndShowCaseAdmin ? 'calc(100% - 68px)' : 'calc(100% - 244px)'};
  height: 100%;

  min-height: 100vh;

  overflow: hidden;

  background-color: rgb(241, 242, 249);

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const AbsoluteContainer = styled.div<{ show?: boolean }>`
  position: absolute;

  top: 75px;
  right: 8px;

  z-index: 4;

  transform: translateX(${({ show }): string => (show ? '0px' : '500px')});

  transition: all 1s;
`;
