import React from 'react';

import { RouteOrRedirectType } from '@plug/routes/types';

const Login = React.lazy(() => import('./entry-point/login'));
const Signup = React.lazy(() => import('./pages/sign-up'));
const RecoveryPassword = React.lazy(() => import('./entry-point/recovery-password'));
const Verification = React.lazy(() => import('./pages/verification'));
const ResponseOrganizationInvite = React.lazy(() => import('./pages/response-organization-invite'));
const SelfCheckout = React.lazy(() => import('./pages/self-checkout'));

export const LOGIN_PATH = '/login';
export const SIGN_UP_PATH = '/signup/:hash?';
export const RECOVER_PATH = '/recovery-password';
export const VERIFICATION_PATH = '/verification/:hash';
export const RESPONSE_ORGANIZATION_INVITE_PATH = '/member-invited/:hash/:response';
export const SELF_CHECKOUT_PATH = '/checkout';

const routes: RouteOrRedirectType[] = [
  {
    path: LOGIN_PATH,
    component: Login,
    title: 'Login',
    exact: true,
  },
  {
    path: RESPONSE_ORGANIZATION_INVITE_PATH,
    component: ResponseOrganizationInvite,
    title: 'Organization Invite',
    exact: true,
  },
  {
    path: SIGN_UP_PATH,
    component: Signup,
    title: 'Sign Up',
    exact: true,
  },
  {
    path: RECOVER_PATH,
    component: RecoveryPassword,
    title: 'Recovery Password',
    exact: true,
  },
  {
    path: VERIFICATION_PATH,
    component: Verification,
    title: 'Verification',
    exact: true,
  },
  {
    path: SELF_CHECKOUT_PATH,
    component: SelfCheckout,
    title: 'Self Checkout',
    exact: true,
  },
];

export default routes;
