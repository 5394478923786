import React from 'react';
import { Flex, Image } from 'rebass';
import { DEFAULT_HUBLY_RETAILER_LOGO_URL, OLIST_HOST_NAME } from '@plug/config/consts';
import styled, { keyframes } from 'styled-components';
import { WhiteLabelStore } from '@plug/redux/store';
import Logo from '../logo';

const Rotate = keyframes`
  0% { opacity: 0.8 }
  100% { opacity: 1;}
`;

const ImageContainer = styled.div`
  animation: ${Rotate} 3s;
  animation-fill-mode: forwards;
`;

const Fallback: React.FC = () => {
  const isOlist = OLIST_HOST_NAME.includes(window.location.hostname);
  const { isWhiteLabel, logo } = WhiteLabelStore.useState(s => s);

  function getFallbackLogo() {
    switch (window.location.hostname) {
      case 'parceiro.efizi.com.br':
        return 'https://efizi.vtexassets.com/assets/vtex/assets-builder/efizi.efizi-store/1.1.1/brand/logo-footer___80df8e52f4bda5f06e070f077b409f50.svg';
      case 'indicaae.lojaintegrada.com.br':
        return 'https://plugone-staging.nyc3.digitaloceanspaces.com/white-label/logo/5265e9530915d6aed998e4657d1cbd05aebf579654a105cec2b8b7593ab24463';
      // case 'localhost':
      //   return 'https://plugone-production.nyc3.digitaloceanspaces.com/assets/loaders/olist-tiny-loader.png';
      case 'parceiros.olist.com':
        return 'https://plugone-production.nyc3.digitaloceanspaces.com/assets/loaders/olist-tiny-loader.png';
      case 'influencer.anapaulacarvalho.com':
        return 'https://cdn.awsli.com.br/400x300/1358/1358683/logo/logo-ana-paula-carvalho-site-yotbzx.png';
      case 'parceiro.espacosmart.com.br':
        return 'https://plugone-production.nyc3.digitaloceanspaces.com/assets/loaders/espaco-smart-loader.png';
      case 'consultora.marykay.com.br':
        return 'https://plugone-production.nyc3.digitaloceanspaces.com/assets/loaders/mary-kay-loader.png';
      case 'parceira.marimariamakeup.com':
        return 'https://plugone-production.nyc3.digitaloceanspaces.com/assets/loaders/mari-maria-loader.png';
      case 'parceiros.lindacasa.com.br':
        return 'https://plugone-production.nyc3.digitaloceanspaces.com/assets/loaders/linda-casa-loader.png';
      case 'parceiro.lojakingspan.com':
        return 'https://plugone-production.nyc3.digitaloceanspaces.com/assets/loaders/king-span-loader.png';
      default:
        return isWhiteLabel && logo ? logo : DEFAULT_HUBLY_RETAILER_LOGO_URL;
    }
  }

  const fallbackLogo = getFallbackLogo();

  return (
    <Flex alignItems="center" justifyContent="center" flex={1} height="100%" width="100%">
      {!isOlist && (
        <Flex maxWidth="500px" maxHeight="500px" alignItems="center" justifyContent="center">
          <ImageContainer>
            {fallbackLogo === DEFAULT_HUBLY_RETAILER_LOGO_URL ? (
              <Logo width="300px" />
            ) : (
              <Image src={fallbackLogo} />
            )}
          </ImageContainer>
        </Flex>
      )}

      {isOlist && (
        <Flex width="100px" height="100px" alignItems="center" justifyContent="center">
          <ImageContainer>
            <Image src={fallbackLogo} />
          </ImageContainer>
        </Flex>
      )}
    </Flex>
  );
};

const Suspense: React.FC = props => {
  const [isCompletedAnimation, setCompletedAnimation] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setCompletedAnimation(true);
    }, 1500);
  }, []);

  return (
    <>
      {!isCompletedAnimation && <Fallback />}
      <React.Suspense fallback="">{props.children}</React.Suspense>
    </>
  );
};

export default Suspense;
