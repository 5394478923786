import { useAuthContext } from '@plug/contexts/auth-context';
import { useNavigationMenuContext } from '@plug/contexts/NavigationMenuContext';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { logoutUserSession } from '../helpers/session';
import { LOGIN_PATH } from '../routing';

export const useLogout = (): ((currentOrganizationName: string) => void)[] => {
  const history = useHistory();
  const { resetContext } = useNavigationMenuContext();
  const { resetContext: resetAuthContext } = useAuthContext();

  const logout = useCallback(
    (currentOrganizationSlug: string) => {
      logoutUserSession();
      resetContext();
      resetAuthContext();
      document?.querySelector('#jsd-widget')?.classList.remove('jsd-show');
      document?.querySelector('#jsd-widget')?.classList.add('jsd-hide');

      let HOST = window.location.host;
      if (currentOrganizationSlug) {
        HOST = window.location.host.replace(`${currentOrganizationSlug}.`, '');
      }

      history.replace(LOGIN_PATH);
    },
    [history, resetAuthContext, resetContext],
  );

  return [logout];
};
