import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Box, Flex } from 'rebass';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Plug, VtexCMS, VtexIO, LojaIntegrada2, Yampi, Tray } from '@plug/ui/icons';
import { Spacer, Text, Button } from '@plug/ui/index';
import { CREATE_INTEGRATION } from '../../graphql/mutations/create-integration';
import isDesktop from '@plug/helpers/is-desktop';
import IntegrationFormLojaIntegrada from '../integration-form-loja-integrada';
import IntegrationMethod from '../integration-method';
import { useTranslation } from 'react-i18next';
import IntegrationFormVtex from '../integration-form-vtex';
import { ThemeStore } from '@plug/redux/store';
import IntegrationFormYampi from '../integration-form-yampi';
import Wake from '@plug/ui/icons/wake.png';
import IntegrationFormWake from '../integration-form-wake';
import Environment from '@plug/ui2/environment';
import IntegrationFormTray from '../integration-form-tray';

interface Values {
  storeName: string;
  storeKey: string;
  storeToken: string;
}

type IntegrateStoreProps = {
  onFinishFirstStep?: () => void;
  status?: boolean;
  refetch: Function;
};

const IntegrateStore: React.FC<IntegrateStoreProps> = ({ onFinishFirstStep, status, refetch }) => {
  const theme = ThemeStore.useState(s => s);

  const [error] = React.useState(false);
  const [stepIndex, setStepIndex] = React.useState(status ? 1 : 0);
  const { t: translate } = useTranslation();
  const [createIntegrationMutation, { loading: createIntegrationLoading }] = useMutation(
    CREATE_INTEGRATION,
  );
  const [integrationMethod, setIntegrationMethod] = React.useState<null | string>();
  const [formikSubmit, setFormikSubmit] = React.useState<null | any>(null);
  const [formikInitialValues, setFormikInitialValues] = React.useState<null | any>({
    initialValues: {},
  });
  const [formikValidationSchema, setFormikValidationSchema] = React.useState<null | any>({
    validationSchema: null,
  });

  const createIntegration = async (
    type: string,
    secrets: {
      accountName?: string;
      xVtexApiAppKey?: string;
      xVtexApiAppToken?: string;
      appKey?: string;
      userSecretKey?: string;
      userStoreCode?: string;
      userToken?: string;
      alias?: string;
      wakeToken?: string;
      wakeStoreFrontToken?: string;
    },
    subtype?: string,
  ): Promise<void> => {
    let optionalMutation: any | undefined;
    const obligatoryMutation = createIntegrationMutation({
      variables: {
        input: {
          secrets,
          type,
          subtype,
        },
      },
    });

    obligatoryMutation
      .then(() => {
        toast.success('Integração confirmada com sucesso!');
        refetch();
        if (onFinishFirstStep) onFinishFirstStep();
      })
      .catch(() => {
        toast.error(
          'Ocorreu um erro ao confirmar integração. Por favor, tente novamente em alguns instantes.',
        );
      });
  };

  const handleChangeIntegrationMethod = (
    type: string,
    onSubmit: any,
    initialValues: any,
    validationSchema: any,
  ): void => {
    setIntegrationMethod(type);
    setFormikSubmit({ onSubmit });
    setFormikInitialValues({ initialValues });
    setFormikValidationSchema({ validationSchema });
  };

  const handleSubmit = (values: any) => {
    formikSubmit.onSubmit(values);
  };

  if (stepIndex === 0) {
    return (
      <Flex flexWrap="wrap">
        {isDesktop && (
          <Flex
            width={7 / 12}
            sx={{
              '@media screen and (max-width: 968px)': {
                width: '100%',
              },
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Flex
              flexDirection="column"
              width="445px"
              alignItems="center"
              sx={{ textAlign: 'center' }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                width="72px"
                height="72px"
                sx={{ borderRadius: '50%', background: '#EBE9F6' }}
              >
                <Plug fill={theme.primary || '#3B24A8'} width={27} height={64} />
              </Flex>
              <Spacer pt="32px" pb="24px">
                <Text fontSize="32px" fontWeight="bold">
                  Integre sua Loja
                </Text>
              </Spacer>
              <Spacer pb="64px">
                <Text fontSize="14px" color="#666372" fontWeight="500">
                  A comunicação nativa entre a MONITFY e as plataformas integra as campanhas
                  promocionais e o acompanhamento dos pedidos realizados através dos links gerados
                  na MONITFY.
                </Text>
                <Spacer pt="14px">
                  <Text fontSize="14px" fontWeight="bold">
                    Comece selecionando a plataforma que você utiliza ao lado.
                  </Text>
                </Spacer>
              </Spacer>
              {/* <Link fontWeight="600">Saiba mais sobre integrações</Link> */}
            </Flex>
            {/* <Link fontWeight="600">Saiba mais sobre integrações</Link> */}
          </Flex>
        )}
        <Box
          width={5 / 12}
          sx={{
            '@media screen and (max-width: 968px)': {
              width: '100%',
            },
          }}
        >
          <Box
            pl={isDesktop ? '32px' : '0px'}
            width={4 / 5}
            sx={{
              '@media screen and (max-width: 768px)': {
                paddingLeft: '0px',
              },
              '@media screen and (max-width: 968px)': {
                width: '100%',
              },
            }}
          >
            <Flex flex={1} flexDirection="column" width="100%">
              <Formik
                onSubmit={handleSubmit}
                initialValues={formikInitialValues.initialValues}
                validationSchema={formikValidationSchema.validationSchema}
                isInitialValid={false}
              >
                {(formikProps: any): JSX.Element => (
                  <Form>
                    <RadioGroup name="integrationMethod" value={integrationMethod}>
                      <IntegrationMethod
                        title="VTEX CMS"
                        type="vtex"
                        icon={<VtexCMS />}
                        selected={integrationMethod === 'vtex'}
                        handleChangeIntegrationMethod={handleChangeIntegrationMethod}
                        initialValues={{
                          vtexName: '',
                          vtexKey: '',
                          vtexToken: '',
                        }}
                        validationSchema={yup.object().shape({
                          vtexName: yup.string().required('Por favor, digite o nome da sua loja.'),
                          vtexKey: yup.string().required('Por favor, digite sua chave.'),
                          vtexToken: yup.string().required('Por favor, digite o token.'),
                        })}
                        onSubmit={(values: any): void => {
                          createIntegration('vtex', {
                            accountName: values.vtexName,
                            xVtexApiAppKey: values.vtexKey,
                            xVtexApiAppToken: values.vtexToken,
                          });
                        }}
                        error={false}
                      />

                      <IntegrationMethod
                        title="VTEX IO"
                        type="vtex_io"
                        icon={<VtexIO />}
                        handleChangeIntegrationMethod={handleChangeIntegrationMethod}
                        selected={integrationMethod === 'vtex_io'}
                        initialValues={{
                          vtexName: '',
                          vtexKey: '',
                          vtexToken: '',
                        }}
                        validationSchema={yup.object().shape({
                          vtexName: yup.string().required('Por favor, digite o nome da sua loja.'),
                          vtexKey: yup.string().required('Por favor, digite sua chave.'),
                          vtexToken: yup.string().required('Por favor, digite o token.'),
                        })}
                        onSubmit={(values: any): void => {
                          createIntegration(
                            'vtex',
                            {
                              accountName: values.vtexName,
                              xVtexApiAppKey: values.vtexKey,
                              xVtexApiAppToken: values.vtexToken,
                            },
                            'vtex_io',
                          );
                        }}
                        error={false}
                      />

                      <IntegrationMethod
                        title="Loja Integrada"
                        type="loja_integrada"
                        icon={<LojaIntegrada2 />}
                        initialValues={{
                          lojaIntegradaToken: '',
                        }}
                        validationSchema={yup.object().shape({
                          lojaIntegradaToken: yup.string().required('Por favor, digite o token.'),
                        })}
                        handleChangeIntegrationMethod={handleChangeIntegrationMethod}
                        onSubmit={(values: any): void => {
                          createIntegration('loja_integrada', {
                            appKey: values.lojaIntegradaToken,
                          });
                        }}
                        selected={integrationMethod === 'loja_integrada'}
                        error={false}
                      />

                      <IntegrationMethod
                        title="Yampi"
                        type="yampi"
                        icon={<Yampi />}
                        initialValues={{
                          yampiUserToken: '',
                          yampiSecretKey: '',
                          yampiAlias: '',
                        }}
                        validationSchema={yup.object().shape({
                          yampiUserToken: yup.string().required('Por favor, digite o user token.'),
                          yampiSecretKey: yup
                            .string()
                            .required('Por favor, digite a user secret key.'),
                          yampiAlias: yup.string().required('Por favor, digite a user secret key.'),
                        })}
                        handleChangeIntegrationMethod={handleChangeIntegrationMethod}
                        onSubmit={(values: any): void => {
                          console.log({ values });
                          createIntegration('yampi', {
                            userToken: values.yampiUserToken,
                            userSecretKey: values.yampiSecretKey,
                            alias: values.yampiAlias,
                          });
                        }}
                        selected={integrationMethod === 'yampi'}
                        error={false}
                      />

                      <Environment allow="staging">
                        <IntegrationMethod
                          title="Wake"
                          type="wake"
                          icon={<img width="40" src={Wake} />}
                          initialValues={{
                            wakeToken: '',
                            storeFrontToken: '',
                          }}
                          validationSchema={yup.object().shape({
                            wakeToken: yup.string().required('Por favor, digite o user token.'),
                            storeFrontToken: yup
                              .string()
                              .required('Por favor, digite o storefront token.'),
                          })}
                          handleChangeIntegrationMethod={handleChangeIntegrationMethod}
                          onSubmit={(values: any): void => {
                            console.log({ values });
                            createIntegration('wake', {
                              wakeToken: values.wakeToken,
                              wakeStoreFrontToken: values.storeFrontToken,
                            });
                          }}
                          selected={integrationMethod === 'wake'}
                          error={false}
                        />
                      </Environment>

                      <Environment allow="staging">
                        <IntegrationMethod
                          title="Tray"
                          type="tray"
                          icon={<Tray />}
                          initialValues={{
                            trayUserToken: '',
                            traySecretKey: '',
                            trayAlias: '',
                            trayStoreCode: '',
                          }}
                          validationSchema={yup.object().shape({
                            trayUserToken: yup.string().required('Por favor, digite o user token.'),
                            traySecretKey: yup
                              .string()
                              .required('Por favor, digite a user secret key.'),
                            trayAlias: yup
                              .string()
                              .required('Por favor, digite a user secret key.'),
                            trayStoreCode: yup
                              .string()
                              .required('Por favor, digite o código da sua loja.'),
                          })}
                          handleChangeIntegrationMethod={handleChangeIntegrationMethod}
                          onSubmit={(values: any): void => {
                            console.log({ values });
                            createIntegration('tray', {
                              userToken: values.trayUserToken,
                              userSecretKey: values.traySecretKey,
                              alias: values.trayAlias,
                              userStoreCode: values.trayStoreCode,
                            });
                          }}
                          selected={integrationMethod === 'tray'}
                          error={false}
                        />
                      </Environment>
                    </RadioGroup>

                    {(integrationMethod === 'vtex' || integrationMethod === 'vtex_io') && (
                      <IntegrationFormVtex values={formikProps.values} />
                    )}
                    {integrationMethod === 'loja_integrada' && <IntegrationFormLojaIntegrada />}
                    {integrationMethod === 'yampi' && <IntegrationFormYampi />}
                    {integrationMethod === 'wake' && (
                      <Environment allow="staging">
                        <IntegrationFormWake />
                      </Environment>
                    )}
                    {integrationMethod === 'tray' && (
                      <Environment allow="staging">
                        <IntegrationFormTray />
                      </Environment>
                    )}

                    <Button
                      loading={createIntegrationLoading}
                      disabled={!formikProps.isValid}
                      type="submit"
                      width="100%"
                      size={isDesktop ? 'large' : 'mobile'}
                      mt="48px"
                    >
                      Integrar
                    </Button>
                  </Form>
                )}
              </Formik>
            </Flex>
          </Box>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex alignItems="center" justifyContent="center">
      <IntegrateStoreFinish
        setStepIndex={setStepIndex}
        onFinishFirstStep={onFinishFirstStep}
        error={error}
      />
    </Flex>
  );
};

type IntegrateStoreFinishProps = {
  error?: boolean;
  onFinishFirstStep?: () => void;
  setStepIndex: Function;
};
const IntegrateStoreFinish: React.FC<IntegrateStoreFinishProps> = ({
  error,
  onFinishFirstStep,
  setStepIndex,
}) => {
  if (error) {
    return (
      <>
        <Flex flexDirection="column" width="354px" alignItems="center" sx={{ textAlign: 'center' }}>
          <Flex
            justifyContent="center"
            alignItems="center"
            width="128px"
            height="128px"
            sx={{
              borderRadius: '50%',
              transform: 'rotate(180deg)',
              background:
                'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #E4000C',
            }}
          >
            <Plug fill="#E4000C" width={48} height={64} />
          </Flex>
          <Spacer pt="24px">
            <Text fontSize="32px" fontWeight="bold">
              Sem conexão
            </Text>
          </Spacer>
          <Spacer pb="32px" pt="8px">
            <Text fontSize="14px" color="#666372" fontWeight="500" lineHeight="20px">
              Infelizmente não conseguimos conectar sua loja VTEX com nosso sistema.
            </Text>
          </Spacer>
          <Button
            onClick={() => setStepIndex(0)}
            variant="fill"
            background="#E4000C"
            pt="16px"
            pb="16px"
            pl="40.5px"
            pr="40.5px"
          >
            Atualizar chave de acesso
          </Button>
        </Flex>
      </>
    );
  }

  return (
    <>
      <Flex flexDirection="column" width="397px" alignItems="center" sx={{ textAlign: 'center' }}>
        <Flex
          justifyContent="center"
          alignItems="center"
          width="128px"
          height="128px"
          sx={{ borderRadius: '50%', background: '#E8F9F5' }}
        >
          <Plug fill="#18C698" width={48} height={64} />
        </Flex>
        <Spacer pb="32px" pt="24px">
          <Text fontSize="32px" fontWeight="bold">
            Integração realizada com sucesso!
          </Text>
        </Spacer>
        <Button
          onClick={onFinishFirstStep}
          variant="fill"
          pt="16px"
          pb="16px"
          pl="68.5px"
          pr="68.5px"
        >
          Convidar membros
        </Button>
      </Flex>
    </>
  );
};

export default IntegrateStore;
