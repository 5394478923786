import getCustomizatedColor from '@plug/helpers/get-customizated-color';
import { Flex } from 'rebass';
import styled, { css } from 'styled-components';

export const MenuItem = styled(Flex)<{ activeHover?: boolean }>`
  justify-content: space-between;
  align-items: center;

  padding: 14px 12px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    padding: 14px 6px;
  }

  background-color: transparent;
  border-radius: ${({ theme }): string => theme.borderRadius.SM};

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
  `}

  ${({ activeHover }) =>
    !activeHover &&
    css`
      &:hover {
        background-color: ${({ theme }): string => theme.colors.primaryLightest};

        // span {
        //   color: ${({ theme }): string => theme.colors.primary};
        // }

        // svg,
        // path {
        //   stroke: ${({ theme }): string => theme.colors.primary};
        // }
      }
    `}

  transition: all 0.3s;
  * {
    transition: all 0.3s;
  }
`;

interface MenuSubItemContainerI {
  parentIsSelected: boolean;
}

export const MenuSubItemContainer = styled(Flex)<MenuSubItemContainerI>`
  flex-direction: column;
  padding-left: 22px;
  overflow: hidden;
  height: 100%;
  max-height: ${({ parentIsSelected }): string => (parentIsSelected ? '300px' : '0px')};
  transition: max-height 0.4s;
`;

export const MenuSubItem = styled(Flex)<{ active?: boolean }>`
  display: flex;
  align-items: center;
  gap: 23px;
  cursor: pointer;

  div {
    background: ${({ theme, active }): string =>
      active
        ? getCustomizatedColor('highlightText', theme.colors.primary)
        : theme.colors.neutralHighMedium};
  }

  span {
    color: ${({ theme, active }): string =>
      active
        ? getCustomizatedColor('highlightText', theme.colors.primary)
        : theme.colors.neutralLow};
  }

  &:hover {
    div {
      transition: all 0.3s linear;
      background: ${({ theme }): string =>
        getCustomizatedColor('highlightText', theme.colors.primaryMedium)};
    }

    span {
      transition: all 0.3s linear;
      color: ${({ theme }): string =>
        getCustomizatedColor('highlightText', theme.colors.primaryMedium)};
    }
  }

  &:last-child {
    margin-bottom: 10px;
  }
`;

export const MenuSubItemLine = styled.div`
  height: 40px;
  width: 2px;
  border-radius: 8px;
`;
