import { createGlobalStyle, css } from 'styled-components';
import hexToRGBA from '@plug/helpers/hex-to-rgba';

export default createGlobalStyle<{ customization: ColorCustomization }>`
${({ customization: colors }): any => {
  const colorSelectRGB = colors?.buttons ? hexToRGBA(`${colors?.buttons}`, 20) : 'inherit';
  const colorSelectRGBHovered = colors?.buttons ? hexToRGBA(`${colors?.buttons}`, 30) : 'inherit';

  return css`
    ${
      colors?.buttons
        ? `
        .select-item[data-state=checked] {
          background: ${colorSelectRGB} !important;
      

          &:hover {
            background: ${colorSelectRGBHovered} !important;
          }
        }

        .day-is-selected {
          &::before {
            background-color: ${colors?.buttons} !important;
          }
        }

        .filter-by-period {
          .round-button-active {
            background-color: ${colors?.buttons} !important;
          }
        }

        .pager-item-active {
          background-color: ${colors?.buttons} !important;
        }

        .toggle input:not(:disabled):checked+.toggle-slider {
          background-color: ${colors?.buttons} !important;
        }

        .ds-button-primary:not(:disabled),
        .link-generator:not(:disabled),
        .button-filled:not(:disabled) {
          border: 1px solid transparent !important;
          background-color: ${colors?.buttons} !important;
          transition: all .5s;

          &:hover:not(:disabled) {
            border: 1px solid ${colors.buttons} !important;
            color: ${colors.buttons} !important;
            background-color: white !important;

            path, svg {
              stroke: ${colors?.buttons} !important;
            }
          }
        }

        .ds-pagination-item-active {
          border: 1px solid transparent!important;
          background-color: ${colors?.buttons} !important;
          transition: all .5s;

          &:hover {
            border: 1px solid ${colors.buttons} !important;
            color: white!important;
            background-color: ${colors.buttons}!important;
          }
        }

        .ds-pagination-item {
          border: 1px solid ${colors?.buttons}!important; !important;
          background-color: white!important;
          color: ${colors.buttons}!important;
          transition: all .5s;

          &:hover {
            border: 1px solid ${colors.buttons} !important;
            color: white!important;
            background-color: ${colors.buttons}!important;
          }
        }
        
        .ds-pagination-button {
          border-color: ${colors?.buttons} !important;

          svg, path {
            stroke: ${colors?.buttons} !important;
          }

          &:hover:not(:disabled) {
            background-color: ${colors?.buttons} !important;
            border-color: ${colors?.buttons} !important;

            svg, path {
              stroke: #fff!important;
            }
          }

          &:focus:not(:disabled) {
            background-color: ${colors?.buttons} !important;
            border-color: ${colors?.buttons} !important;
        
            path {
              stroke: #fff!important;
            }
          }

          &:disabled {
            background-color: #eeeeed!important;
            border-color: #c8ccd5!important;
        
            path {
              stroke: #c8ccd5!important;
            }
          }
        }
    `
        : ''
    }

    ${
      colors?.textButton
        ? `
        .day-is-selected {
          span {
            color: ${colors?.textButton} !important;

            &:hover:not(:disabled) {
              color: ${colors?.textButton} !important;
            }
          }
        }

        .filter-by-period {
          .round-button-active {
            color: ${colors?.textButton} !important;
          }
        }

        .ds-button-primary:not(:disabled) {
          color: ${colors?.textButton} !important;
        }

        .pager-item-active {
          color: ${colors?.textButton} !important;

          .pager-item-page {
            color: ${colors?.textButton} !important;
          }
        }

        .ds-pagination-item-active {
          color: ${colors?.textButton} !important;
          font-weight: bold !important;

          &:hover {
            color: white!important;
          }
        }

        .ds-pagination-item {
          color: ${colors?.buttons} !important;

          &:hover {
            border: 1px solid ${colors.buttons} !important;
            color: white!important;
            background-color: ${colors.buttons}!important;
          }
        }
    `
        : ''
    }

    ${
      colors?.header
        ? `.header {
      background-color: ${colors?.header} !important;
    }`
        : ''
    }

    ${
      colors?.spinner
        ? `
        .ds-spinner {
          path {
            fill: ${colors?.spinner} !important;
          }
        }
    `
        : ''
    }
    
    ${
      colors?.highlightText
        ? `
      .ds-highlight-text {
        color: ${colors?.highlightText} !important;
      }
      
      .ds-link {
        color: ${colors?.highlightText} !important;
      }

      .breadcrumb-item a {
        color: ${colors?.highlightText} !important;
      }
      
      .ds-tab-item {
        color: ${colors?.highlightText} !important;

        &--selected {
          border-color: ${colors?.highlightText} !important;
        }
      }
    `
        : ''
    }
    
    ${
      colors?.icons
        ? `
      .ds-icon:not(.ds-envelope):not(.ds-instagramColor):not(.ds-messenger):not(.ds-whatsapp):not(.ds-collumns):not(.ds-check):not(.ds-chevronLeft):not(.ds-chevronRight) {
        path {
          stroke: ${colors?.icons} !important;
          fill: transparent !important;
        }
      }
  `
        : ''
    }

    @media screen and (max-width: 768px) {
      .pager {
        .pager-button {
          height: 26px;
          width: 26px;
          padding: 0px;
        }

        .pager-item {
          height: 26px;
          width: 26px;
          padding: 0px;
        }
      }
    } 
  `;
}}
`;
