import { useCallback, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import cookie from 'js-cookie';
import cryptoCookie from '../helpers/cookie';

import {
  USER_ORGANIZATION_PERMISSIONS_STORAGE_KEY,
  USER_SERVICES_PERMISSIONS_STORAGE_KEY,
  CURRENT_ORGANIZATION_STORAGE_KEY,
  ORGANIZATION_RULES_PERMISSION_STORAGE_KEY,
  MARY_KAY_WHITELABEL,
} from '@plug/config/consts';

import { useAuthContext } from '@plug/contexts/auth-context';
import { useHistory } from 'react-router';
import { OrganizationStore, ThemeStore } from '@plug/redux/store';
import removeNullsInObject from '@plug/helpers/remove-empty-object';
import { useNavigationMenuContext } from '@plug/contexts/NavigationMenuContext';
import { saveLocalInfo } from '@plug/helpers/local-info';

type SetCurrentOrganizationReturnInfosData = {
  setCurrentOrganizationReturnInfos: {
    organization: {
      id: string;
      logo: string;
      contactEmail: string;
      name: string;
      active: boolean;
      slug: string;
      domain: string;
      public: boolean;
      freeTrial: boolean;
      freePlan: string;
      freeTrialExpires: string;
      organizationRole: {
        name: string;
      };
      integration: {
        type: string;
        status: string;
        createdAt: string;
        updatedAt: string;
        asSaas: boolean;
      };
    };
    whiteLabelInfos: {
      primaryColor: string;
      secondColor: string;
      tertiaryColor: string;
      logo: string;
    };
  };
};

type SetCurrentOrganizationReturnInfosVariables = {
  input: {
    organizationId: string;
  };
};

const SET_CURRENT_ORGANIZATION_RETURN_INFOS = gql`
  mutation setCurrentOrganizationReturnInfos($input: SetCurrentOrganizationReturnInfosInput!) {
    setCurrentOrganizationReturnInfos(input: $input) {
      organization {
        id
        logo
        contactEmail
        name
        active
        slug
        domain
        public
        logo
        freeTrial
        freeTrialExpires
        freePlan
        organizationRole {
          name
        }
        integration {
          type
          subtype
          status
          createdAt
          updatedAt
          asSaas
        }
      }
      whiteLabelInfos {
        primaryColor
        secondColor
        tertiaryColor
        logo
      }
    }
  }
`;

const USER_PERMISSION_QUERY = gql`
  query userPermission {
    userPermission(input: { serviceName: affiliate }) {
      organizationRules {
        newPlanRules {
          maxGeneratedLinks
          features {
            transactional_mail_basic
            transactional_mail_personalized
            implemented_strategy_cs
            support_ticket
            support_chat
            support_whatsapp
            support_video
            whitelabel
            whitelabel_personalized
            hierarchy_administrators
            resellers
            commissions
            commissions_goals
            commissions_settings
            digital_showcase
            report_finantial
            report_orders
            report_commissions
            hubly_store
            dashboard
            shared_links
            public_organization
            layout_customization
            webhook
            inside_sales
          }
          providers {
            vtex
            loja_integrada
            iugu
          }
        }
        planRules {
          serviceName
          rules {
            maxTransactionTax
            maxTeammates
            maxAnalysts
            maxSales
            support
            training
            affiliateStore
            sso
            providers {
              name
              status
            }
          }
        }
      }
      organizationPermissions {
        permissionName
        organizationRoleName
        grant
      }
      userServicePermissions {
        permissionName
        serviceRoleName
        grant
        service {
          name
        }
      }
    }
  }
`;

function useSetOrganization() {
  const { selectCurrentOrganization } = useAuthContext();
  const { resetContext } = useNavigationMenuContext();
  const history = useHistory();
  const [setCurrentOrganizationMutation, { loading: loadingOrganization }] = useMutation<
    SetCurrentOrganizationReturnInfosData,
    SetCurrentOrganizationReturnInfosVariables
  >(SET_CURRENT_ORGANIZATION_RETURN_INFOS);

  const [loadUserPermissions, { loading: loadingPermissions }] = useLazyQuery(
    USER_PERMISSION_QUERY,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const loadCookies = (permission: any): void => {
    if (permission?.userPermission?.organizationPermissions)
      cookie.set(
        USER_ORGANIZATION_PERMISSIONS_STORAGE_KEY,
        JSON.stringify(permission?.userPermission?.organizationPermissions),
        {
          domain: window.location.hostname,
        },
      );

    if (permission?.userPermission?.organizationRules)
      saveLocalInfo(
        ORGANIZATION_RULES_PERMISSION_STORAGE_KEY,
        removeNullsInObject(permission?.userPermission?.organizationRules),
      );

    if (permission?.userPermission?.userServicePermissions)
      saveLocalInfo(
        USER_SERVICES_PERMISSIONS_STORAGE_KEY,
        permission?.userPermission?.userServicePermissions,
      );
  };

  const setCurrentOrganization = async (
    organization: any,
    isWhiteLabel: boolean,
    hasOrganizationId?: boolean,
    isEmptyDigitalShowcase?: boolean,
  ): Promise<void> => {
    resetContext();
    const { data }: any = await setCurrentOrganizationMutation({
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          organizationId: organization.id,
        },
      },
    });

    if (data?.setCurrentOrganizationReturnInfos && selectCurrentOrganization) {
      const { data: permissionData } = await loadUserPermissions();

      ThemeStore.update(s => {
        s.logo = data.setCurrentOrganizationReturnInfos.whiteLabelInfos.logo;
        s.primary = data.setCurrentOrganizationReturnInfos.whiteLabelInfos.primaryColor;
        s.secondary = data.setCurrentOrganizationReturnInfos.whiteLabelInfos.secondColor;
        s.tertiary = data.setCurrentOrganizationReturnInfos.whiteLabelInfos.tertiaryColor;
        s.updateBySetCurrentOrganization = true;
      });

      selectCurrentOrganization(data.setCurrentOrganizationReturnInfos.organization);

      cookie.set(
        CURRENT_ORGANIZATION_STORAGE_KEY,
        JSON.stringify(data.setCurrentOrganizationReturnInfos.organization),
        {
          domain: window.location.hostname,
        },
      );

      loadCookies(permissionData);

      const role =
        data?.setCurrentOrganizationReturnInfos?.organization?.organizationRole?.[0]?.name;

      if (
        MARY_KAY_WHITELABEL.includes(window.location.hostname) &&
        isEmptyDigitalShowcase &&
        role !== 'ADMIN'
      ) {
        history.push(
          `/org/${data.setCurrentOrganizationReturnInfos.organization.slug}/affiliate/showcase`,
        );
      } else {
        history.push(`/org/${data.setCurrentOrganizationReturnInfos.organization.slug}/overview`);
      }
    }
  };

  return {
    setCurrentOrganization,
    loading: loadingPermissions || loadingOrganization,
  };
}

export default useSetOrganization;
