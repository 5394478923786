import React from 'react';
import { RouteOrRedirectType } from '@plug/routes/types';
import Loadable from 'react-loadable';
import { Flex } from 'rebass';
import { Spinner } from '@gohubly/design-system';
import CheckoutContextProvider, { useCheckoutContext } from './contexts/CheckoutContext';
import CheckoutLogin from './components/checkout/components/checkout-login';
import { Route } from 'react-router-dom';
import CheckoutCatalog from './components/checkout/components/checkout-catalog';
import { CheckoutCartContextProvider } from './contexts/CheckoutCartContext';

const Loading = (): JSX.Element => (
  <Flex width="100%" height="80vh" justifyContent="center" alignItems="center">
    <Spinner />
  </Flex>
);

const SignaturesPage = Loadable({
  loader: () => import('./pages/signatures'),
  loading: Loading,
});

const OrderPage = Loadable({
  loader: () => import('./pages/order'),
  loading: Loading,
});

const DetailedOrderPage = Loadable({
  loader: () => import('./pages/detailed-order'),
  loading: Loading,
});

const GenerateLinkPage = Loadable({
  loader: () => import('./pages/generate-link'),
  loading: Loading,
});

const ComissionPage = Loadable({
  loader: () => import('./pages/comission'),
  loading: Loading,
});

const PaymentsPage = Loadable({
  loader: () => import('./pages/payments'),
  loading: Loading,
});

const Members = Loadable({
  loader: () => import('./pages/members'),
  loading: Loading,
});

const MemberDetail = Loadable({
  loader: () => import('./pages/member-detail'),
  loading: Loading,
});

const DigitalShowcase = Loadable({
  loader: () => import('./pages/digital-showcase'),
  loading: Loading,
});

const DetailedShowcaseTemplate = Loadable({
  loader: () => import('./pages/detailed-showcase-template'),
  loading: Loading,
});

const ShowcaseUpdateSection = Loadable({
  loader: () => import('./pages/showcase-update-section'),
  loading: Loading,
});

const CreateShowcaseTemplate = Loadable({
  loader: () => import('./pages/create-showcase-template'),
  loading: Loading,
});

const InsideSalesPage = Loadable({
  loader: () => import('./pages/inside-sales'),
  loading: Loading,
});

const CheckoutPage = Loadable({
  loader: () => import('./pages/checkout'),
  loading: Loading,
});

const AbandonedCartPage = Loadable({
  loader: () => import('./pages/abandoned-cart'),
  loading: Loading,
});

const MemberInsightsPage = Loadable({
  loader: () => import('./pages/member-insights'),
  loading: Loading,
});

const AppStorePage = Loadable({
  loader: () => import('./pages/app-store'),
  loading: Loading,
});

const AppsPage = Loadable({
  loader: () => import('./pages/apps'),
  loading: Loading,
});

const AffiliateAppPage = Loadable({
  loader: () => import('./pages/member-app'),
  loading: Loading,
});

const MyAccountPage = Loadable({
  loader: () => import('./pages/my-account'),
  loading: Loading,
});

const MemberErrorListImportPage = Loadable({
  loader: () => import('./pages/member-error-list-import'),
  loading: Loading,
});

const AffiliatesLogPage = Loadable({
  loader: () => import('./pages/affiliates-log'),
  loading: Loading,
});

export const AFFILIATE_SAAS_SIGNATURES = '/org/:organizationSlug/affiliate/signatures';
export const ORDER_PATH = '/org/:organizationSlug/affiliate/orders';
export const DETAILED_ORDER_PATH = '/org/:organizationSlug/affiliate/order/:orderId';
export const AFFILIATE_GENERATE_LINK_PATH = '/org/:organizationSlug/affiliate/link-generator';
export const AFFILIATE_ORDER_COMISSION = '/org/:organizationSlug/affiliate/commission';
export const AFFILIATE_APP_STORE = '/org/:organizationSlug/affiliate/app-store';
export const AFFILIATE_APPS = '/org/:organizationSlug/affiliate/apps';
export const AFFILIATE_APP = '/org/:organizationSlug/affiliate/app/:appId';
export const AFFILIATE_PAYMENTS = '/org/:organizationSlug/affiliate/payments';
export const MEMBERS_PATH = '/org/:organizationSlug/affiliate/members';
export const MEMBER_DETAIL_PATH = '/org/:organizationSlug/affiliate/member/:affiliateId';
export const SHOWCASE_PATH = '/org/:organizationSlug/affiliate/showcase';
export const SHOWCASE_UPDATE_SECTIONS_PATH =
  '/org/:organizationSlug/affiliate/showcase/:templateId';
export const DETAILED_SHOWCASE_TEMPLATE_PATH =
  '/org/:organizationSlug/affiliate/showcase/template/:templateId';
export const CREATE_SHOWCASE_TEMPLATE = '/org/:organizationSlug/affiliate/showcase/create';
export const INSIDE_SALES = '/org/:organizationSlug/affiliate/inside-sales';
export const CHECKOUT_PATH = '/org/:organizationSlug/affiliate/checkout';
export const ABANDONED_CART = '/org/:organizationSlug/affiliate/abandoned-carts';
export const MY_ACCOUNT = '/org/:organizationSlug/affiliate/account';
export const MEMBER_INSIGHTS = '/org/:organizationSlug/affiliate/member-insights';
export const MEMBER_ERROR_LIST_IMPORT_PATH =
  '/org/:organizationSlug/affiliate/member-error-list-import';
export const AFFILIATES_LOG_PATH = '/org/:organizationSlug/affiliate/logs';
// export const AFFILIATE_SAAS_SIGNATURES = '/:organizationName/affiliate/signatures';
// export const ORDER_PATH = '/:organizationName/affiliate/orders';
// export const AFFILIATE_GENERATE_LINK_PATH = '/:organizationName/affiliate/link-generator';
// export const AFFILIATE_ORDER_COMISSION = '/:organizationName/affiliate/commission';
// export const AFFILIATE_PAYMENTS = '/:organizationName/affiliate/payments';
// export const MEMBERS_PATH = '/:organizationName/affiliate/members';
// export const SHOWCASE_PATH = '/:organizationName/affiliate/showcase';
// export const INSIDE_SALES = '/:organizationName/affiliate/inside-sales';

const routes: RouteOrRedirectType[] = [
  {
    path: AFFILIATE_SAAS_SIGNATURES,
    component: SignaturesPage,
    exact: true,
    title: 'Assinaturas',
  },
  {
    path: ORDER_PATH,
    component: OrderPage,
    exact: false,
    title: 'Affiliado Pedido',
  },
  {
    path: DETAILED_ORDER_PATH,
    component: DetailedOrderPage,
    exact: false,
    title: 'Affiliado Pedido',
  },
  {
    path: AFFILIATE_APP_STORE,
    component: AppStorePage,
    exact: true,
    title: 'App Store',
  },
  {
    path: AFFILIATE_APPS,
    component: AppsPage,
    exact: true,
    title: 'Apps',
  },
  {
    path: AFFILIATE_APP,
    component: AffiliateAppPage,
    exact: true,
    title: 'App',
  },
  {
    path: AFFILIATE_GENERATE_LINK_PATH,
    component: GenerateLinkPage,
    exact: true,
    title: 'Divulgação / Gerador de Link',
  },
  {
    path: AFFILIATE_ORDER_COMISSION,
    component: ComissionPage,
    exact: true,
    title: 'Comissão',
  },
  {
    path: AFFILIATE_PAYMENTS,
    component: PaymentsPage,
    exact: true,
    title:
      window.location.hostname !== 'indicaae.lojaintegrada.com.br'
        ? 'Pagamentos'
        : 'Dados de pagamento',
  },
  {
    path: INSIDE_SALES,
    component: InsideSalesPage,
    exact: true,
    title: 'Inside Sales',
  },
  {
    path: `${CHECKOUT_PATH}/*`,
    component: ({ routes = [] }) => (
      <CheckoutContextProvider>
        <CheckoutCartContextProvider>
          {routes.map((route: any, i: any) => (
            <Route
              key={i}
              path={route.path}
              exact
              render={(props: any): JSX.Element => (
                <route.component {...props} routes={route.routes} />
              )}
            />
          ))}
        </CheckoutCartContextProvider>
      </CheckoutContextProvider>
    ),
    exact: true,
    title: 'Checkout',
    routes: [
      {
        path: `${CHECKOUT_PATH}/onboarding`,
        component: CheckoutLogin,
      },
      {
        path: `${CHECKOUT_PATH}/catalog`,
        component: CheckoutCatalog,
        exact: true,
        title: 'Catalog',
      },
    ],
  },
  {
    path: MEMBERS_PATH,
    component: Members,
    privateRoute: true,
    exact: true,
    title: 'Membros',
  },
  {
    path: MEMBER_DETAIL_PATH,
    component: MemberDetail,
    privateRoute: true,
    exact: true,
    title: 'Informações Membro',
  },
  {
    path: CREATE_SHOWCASE_TEMPLATE,
    component: CreateShowcaseTemplate,
    privateRoute: true,
    exact: true,
    title: 'Vitrine Digital',
  },
  {
    path: DETAILED_SHOWCASE_TEMPLATE_PATH,
    component: DetailedShowcaseTemplate,
    privateRoute: true,
    exact: true,
    title: 'Vitrine Digital',
  },
  {
    path: SHOWCASE_UPDATE_SECTIONS_PATH,
    component: ShowcaseUpdateSection,
    privateRoute: true,
    exact: true,
    title: 'Vitrine Digital',
  },
  {
    path: SHOWCASE_PATH,
    component: DigitalShowcase,
    privateRoute: true,
    exact: true,
    title: 'Vitrine Digital',
  },
  {
    path: ABANDONED_CART,
    component: AbandonedCartPage,
    privateRoute: true,
    exact: true,
    title: 'Recuperador',
  },
  {
    path: MY_ACCOUNT,
    component: MyAccountPage,
    privateRoute: true,
    exact: true,
    title: 'Minha Conta',
  },
  {
    path: MEMBER_INSIGHTS,
    component: MemberInsightsPage,
    privateRoute: true,
    exact: true,
    title: 'Member Insights',
  },
  {
    path: MEMBER_ERROR_LIST_IMPORT_PATH,
    component: MemberErrorListImportPage,
    privateRoute: true,
    exact: true,
    title: 'Lista de erro de importação de membros',
  },
  {
    path: AFFILIATES_LOG_PATH,
    component: AffiliatesLogPage,
    privateRoute: true,
    exact: true,
    title: 'Lista de erro de importação de membros',
  },
];

export default routes;
