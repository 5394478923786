import React, { FC, createContext, useContext, useState } from 'react';
import NotificationsDrawer from '@plug/components/notifications-drawer';
import { NotificationProps } from '@plug/components/notifications-drawer/components/notification/notification.types';

interface INotificationContext {
  notifications: NotificationProps[];
  addNotifications: (items: NotificationProps[]) => void;
  show: () => void;
  hide: () => void;
  toggle: () => void;
}

const NotificationsContext = createContext<INotificationContext>({
  notifications: [],
  addNotifications: null,
  show: null,
  hide: null,
  toggle: null,
});

export const useNotificationContext = () => useContext(NotificationsContext);

export const NotificationsContextProvider: FC = ({ children }) => {
  const [show, setShow] = useState(false);
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        addNotifications: items => setNotifications(notifications => [...items, ...notifications]),
        show: () => setShow(true),
        hide: () => setShow(false),
        toggle: () => setShow(show => !show),
      }}
    >
      <NotificationsDrawer notifications={notifications} show={show} />
      {children}
    </NotificationsContext.Provider>
  );
};
