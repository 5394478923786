import React, { createContext, useState } from 'react';
import cookie from 'js-cookie';
import { TOKEN_LOCAL_STORAGE_KEY, CURRENT_ORGANIZATION_STORAGE_KEY } from '@plug/config/consts';

import { AuthContextType, dummyContext } from './types';

export const AuthContext = createContext<AuthContextType>(dummyContext);

export const useAuthContext = (): AuthContextType => React.useContext(AuthContext);

const AuthContextProvider: React.FC = ({ children }) => {
  const currentOrganizationFromStorage = cookie.get(CURRENT_ORGANIZATION_STORAGE_KEY)
    ? JSON.parse(decodeURIComponent(cookie.get(CURRENT_ORGANIZATION_STORAGE_KEY) || ''))
    : undefined;

  const [user] = useState<User>();
  const [currentOrganization, setCurrentOrganization] = useState(currentOrganizationFromStorage);
  const [disabledOrganizations, setDisableOrganizations] = useState([]);

  const selectCurrentOrganization = (organization: any): void => {
    if (organization) {
      setCurrentOrganization(organization);

      cookie.set(CURRENT_ORGANIZATION_STORAGE_KEY, JSON.stringify(organization), {
        domain: window.location.hostname,
      });
    }
  };

  const resetContext = (): void => {
    setCurrentOrganization(undefined);
    setDisableOrganizations([]);
  };

  // const verifySession = (): boolean => !!localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);
  const verifySession = (): boolean => cookie.get(TOKEN_LOCAL_STORAGE_KEY) !== undefined;

  const verifyProfiles = (requestedProfiles: string[]): boolean =>
    verifySession() &&
    !!requestedProfiles.find(requestedProfile => user?.profiles.includes(requestedProfile));

  return (
    <AuthContext.Provider
      value={{
        selectCurrentOrganization,
        currentOrganization,
        setCurrentOrganization,
        resetContext,
        verifySession,
        verifyProfiles,
        disabledOrganizations,
        disableOrganization(incomingIds) {
          setDisableOrganizations(ids => {
            if (!incomingIds.length) return [];

            return [...ids, ...incomingIds];
          });
        },
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
